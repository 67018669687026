import React, { useEffect, useState } from 'react';
import { Button, Divider, Flex, Text, Spinner } from "@chakra-ui/react";
import { BsGearFill } from "react-icons/bs";
import { Link, useHistory } from "react-router-dom";

import { useAuth } from "../../Context/auth";
import { WrapperBG } from "../wrapperBG";
import api_url from "../../Services/Api";
import ImageOrPlaceholder from '../../Components/ImageOrPlacehold';



function MainConfig() {
  const { Logout } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const refreshPage = () => {
    window.location.reload();
  }
  const [client, setClient] = useState<IClient[]>([]);

  type PartialResponse = {
    data: IClient[];

  };

  async function fetchClient() {
    const response = await api_url.get<PartialResponse>(
      "/users/me/clients"
    );
    setClient(response.data.data);
  }

  async function clientCarregando() {
    await fetchClient();
    setIsLoading(false);
  }
  // TOKEN_KEY
  useEffect(() => {
    clientCarregando();

  }, []);

  const history = useHistory();
  return (
    <WrapperBG
      funcaoIcon={() => {
        history.goBack();
      }}
    >
      <Text
        textColor="#2654C5"
        fontSize="2xl"
        p={1}

        fontWeight="600"
        alignSelf="center"
      >
        Clientes
      </Text>
      <Flex zIndex={3} justifyContent="center">
        <Divider
          border="3px"
          orientation="horizontal"
          bgColor="#665AE7"
          borderColor="#665AE7"
          w="80%"
        />
      </Flex>

      {/* <Skeleton startColor="#665AE7" endColor="white" height="50px" /> */}

      <Flex

        h="75vh" >

        <Flex flexDir="column" w="100%" overflow="auto" marginTop='2rem'>
          {isLoading ?
            <Flex padding="0 1rem 0 0.7rem" align="center" alignSelf="center" paddingBottom="1.3rem">
              <Spinner thickness="4px" color="blue.500" />
            </Flex>
            :
            client.map((client) => (
              <Flex
                key={client.id}
                justifyContent="space-between"
                alignSelf="center"
                borderRadius="xl"
                mt="2"
                w="90%"
                p={2}
                color="black"
                textColor="#2654C5"
                boxShadow="md"

              >
                <Flex flexDirection="row" justifyContent="center" alignItems="center">
                  <ImageOrPlaceholder
                    className="logo-client"
                    src={`https://001.${client.baseDomain}/api/media/favicon/`}
                  />
                  <Text
                    fontWeight="bold"
                    fontSize="1.1rem"
                    textColor="#44496F"
                    alignSelf="center"
                  >
                    {client.clientName}
                  </Text>
                </Flex>

                <Text
                  fontWeight="bold"
                  fontSize="xl"
                  textColor="#665AE7"
                  alignSelf="center"
                >
                  <Button
                    background="transparent"
                    fontSize="1.4rem">
                    <Link to={`/MainConfig/${client.id}`}>
                      <BsGearFill />
                    </Link>
                  </Button>
                </Text>
              </Flex>
            ))}
        </Flex>

      </Flex>
      <Flex alignSelf='center' padding='0rem'>
        <Link to="/">
          <Button bgColor='transparent'
            onClick={() => {
              Logout();
              refreshPage();
            }

            }>
            <Text fontSize='2xl' textColor='#665AE7' bgColor='transparent'>
              Deslogar
            </Text>
          </Button>
        </Link>

      </Flex>
    </WrapperBG>
  );
}
export default MainConfig;


