import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Flex, SkeletonCircle, Text, Input, InputLeftElement, InputGroup } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { IoTvOutline } from "react-icons/io5";
import { FiFilter } from "react-icons/fi";
import { IoMdPhonePortrait } from "react-icons/io";

import { Link } from "react-router-dom";
import { BsArrowReturnLeft } from "react-icons/bs";
import api_url from "../../Services/Api";
import UIInfiniteScroll from "../../Components/InfiniteScroll";
import FilterOverlay from "./FilterOverlay";


type EquipamentType = "POS" | "TV";
interface IEquipament {
  nome: string,
  id: string,
  tipo: EquipamentType,
  server: string,
  online: boolean;
  clientId: string;
}

export interface IFilter {
  tv: boolean;
  pos: boolean;
  status: boolean | undefined;
}

type ApiResponse<T> = {
  data: T[];
  servers: Record<string, IServer>;
  total: number;
  pageCount: number;
};

type PageData = Record<
  EquipamentType,
  Pick<ApiResponse<never>, 'total' | 'pageCount'> & { page: number }
>

const initialPageData: PageData = {
  POS: {
    page: 0,
    pageCount: 1,
    total: 0,
  },
  TV: {
    page: 0,
    pageCount: 1,
    total: 0,
  }
}

const UNDEFINED_PROMISE = new Promise<undefined>((resolve) => resolve(undefined));

function toEquipament(data: (ITvs | IPos)[] | undefined): IEquipament[] {
  if (!data)
    return [];

  return data
    .filter((eq: ITvs | IPos) => eq.mac_address && eq.mac_address.replaceAll(':', '').length === 12) //filtra mac address invalidos ou mal formatados
    .map((eq) => ({
      nome: eq.estabelecimento_nome,
      id: eq.mac_address,
      tipo: 'token' in eq ? 'TV' : 'POS',
      server: eq.server,
      online: !!eq.online,
      clientId: eq.client
    }));
}

function Equipament() {
  const [servers, setServers] = useState<Record<string, IServer>>({});
  const [clientes, setClientes] = useState<IClient[]>([]);

  const [equipaments, setEquipament] = useState<IEquipament[]>([]);
  const [filter, setFilter] = useState<IFilter>({ tv: false, pos: false, status: undefined });
  const [isLoading, setIsLoading] = useState(true);

  //-----
  const [selectedClients, setSelectedClients] = useState<string[]>([])
  const [showFilter, setShowFilter] = useState(false);
  const [estabelecimento, setEstabelecimento] = useState('');
  const scrollRef = useRef<HTMLDivElement>(null);
  const [pageData, setPageData] = useState<PageData>(initialPageData);
  const isLastPage = useMemo(() => {
    return (!filter.pos || pageData.POS.page === pageData.POS.pageCount) && (!filter.tv || pageData.TV.page === pageData.TV.pageCount)
  }, [pageData, filter.pos, filter.tv])


  const fetchPos = useCallback(async (_selectedClients: string[] = [], page = 1) => {
    const response = await api_url.get<ApiResponse<IPos>>("/pos",
      {
        params: {
          page: page,
          // client: selectedClients.length < 1 ? undefined : selectedClients,
          // online: filter.status !== undefined ? +filter.status : undefined,
          // filter: undefined
        }
      });
    return response.data;
  }, []);


  const fetchTvs = useCallback(async (_selectedClients: string[] = [], page = 1) => {
    const response = await api_url.get<ApiResponse<ITvs>>("/telas",
      {
        params: {
          page: page,
          // client: selectedClients.length < 1 ? undefined : selectedClients,
          // online: filter.status !== undefined ? +filter.status : undefined,
          // filter: undefined
        }
      });

    return response.data;
  }, []);



  async function fetchClient() {
    type apiData = Omit<ApiResponse<IClient>, 'servers'>;
    const response = await api_url.get<apiData>("/users/me/clients");
    setClientes(response.data.data);
  }

  type fetchOptions = { setLoading?: boolean, pages?: number[], reset?: boolean, targetClients?: string[] }
  const equipamentCarregando = useCallback(async (options: fetchOptions = {}) => {
    const { setLoading, pages, reset, targetClients = selectedClients } = options;

    if (setLoading) setIsLoading(true);

    const tvsPage = !reset ? pages?.[0] || 1 : 1;
    const posPage = !reset ? pages?.[1] || 1 : 1;

    const [responseTv, responsePos] = await Promise.allSettled([
      reset || tvsPage > pageData.TV.page ? fetchTvs(targetClients, tvsPage) : UNDEFINED_PROMISE,
      reset || posPage > pageData.POS.page ? fetchPos(targetClients, posPage) : UNDEFINED_PROMISE
    ]);

    const tvsData = responseTv.status === 'fulfilled' ? responseTv.value : undefined;
    const posData = responsePos.status === 'fulfilled' ? responsePos.value : undefined;

    if (!setLoading)
      setServers({
        ...(tvsData?.servers || {}),
        ...(posData?.servers || {}),
      });

    setPageData({
      TV: {
        page: tvsPage || pageData.TV.page,
        pageCount: tvsData?.pageCount || pageData.TV.pageCount,
        total: tvsData?.total || pageData.TV.total
      },
      POS: {
        page: posPage || pageData.POS.page,
        pageCount: posData?.pageCount || pageData.POS.pageCount,
        total: posData?.total || pageData.POS.total,
      }
    })

    setEquipament([
      ...(reset ? [] : equipaments),
      ...toEquipament(tvsData?.data),
      ...toEquipament(posData?.data),
    ].filter((eq, index, arr) => {
      return arr.findIndex((x) => x.id === eq.id) === index;
    }));
    if (!setLoading) await fetchClient();

    setIsLoading(false);

  }, [equipaments, fetchPos, fetchTvs, pageData.POS.page, pageData.POS.pageCount, pageData.POS.total, pageData.TV.page, pageData.TV.pageCount, pageData.TV.total, selectedClients]);

  const nextPage = useCallback(async () => {

    if (isLoading || estabelecimento) return;

    const newPages = [
      pageData.TV.page,
      pageData.POS.page,
    ]

    if (pageData.TV.page + 1 <= pageData.TV.pageCount)
      newPages[0] += 1;

    if (pageData.POS.page + 1 <= pageData.POS.pageCount)
      newPages[1] += 1;

    await equipamentCarregando({ setLoading: true, pages: newPages });
    
  }, [isLoading, estabelecimento, pageData.TV.page, pageData.TV.pageCount, pageData.POS.page, pageData.POS.pageCount, equipamentCarregando]);

  useEffect(() => {
    equipamentCarregando({ reset: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      width="100%"
    >
      {/* back button */}
      <Flex alignSelf="self-end" width="100%">
        <Link to='/Main'>
          <Button
            margin-right="auto"
            color="#665AE7"
            colorScheme="transparent"
            marginLeft="2%"
            marginTop="2%"
          // onClick={() => history.goBack()}
          >
            <BsArrowReturnLeft
              size={40}
              colorInterpolation="black"
            />
          </Button>
        </Link>
      </Flex>

      <Text fontWeight="bold" fontSize="3xl" textColor="#665AE7">
        Equipamentos {/*{pageData.POS.page} | {pageData.TV.page}*/}
      </Text>

      {/* campo de busca */}
      <Flex
        paddingTop="1rem"
        paddingRight="1rem"
        paddingLeft="1rem"
        w="100%"
        justifyContent="center"
        borderRadius="full"

      >
        <Flex marginRight="0.5rem" w="80%" borderRadius="full">
          <InputGroup borderRadius="full">
            <InputLeftElement
              borderRadius="full"
              pointerEvents="none"
            >
              <SearchIcon color="#665AE7" />
            </InputLeftElement>
            <Input
              variant="unstyled"
              className="shadow-input-filter"
              placeholder="Procurar"
              borderRadius="full"
              outline="none"
              value={estabelecimento}
              onChange={(e) => setEstabelecimento(e.target.value)}

            />
          </InputGroup>
        </Flex>
        <Flex>
          <Button
            bgColor="#665AE7"
            minHeight="0px"
            borderRadius="full"
            padding="0"
            margin="0"
            border="0"
            size="10"
            fontSize="lg"
            h="2.5rem "
            w="2.5rem"
            colorScheme="transparent"
            onClick={() => {
              setShowFilter(!showFilter);
            }}
          >
            <FiFilter color="white" size="1.2rem" />
          </Button>
        </Flex>
      </Flex>

      {/* Botão do filtro */}
      <FilterOverlay
        show={showFilter}
        clients={clientes}
        filter={filter}
        selectedClients={selectedClients}
        onSelectClient={(cl) => {
          setSelectedClients(cl);
          equipamentCarregando({ reset: true, setLoading: true, targetClients: cl })
        }}
        refresh={() => {
          equipamentCarregando({ reset: true, setLoading: true, targetClients: selectedClients })
        }}
        onSetFilter={setFilter}
      />
      {/* 2 buttons */}
      <Flex
        flexDir="row"
        padding="2.1rem 2rem"
        w="100%"
        justifyContent="space-between"
      >
        <Button
          boxShadow="md"
          flexDir="column"
          height="9rem"
          w="47%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="2xl"
          className="shadow-btn"
          background={filter.pos ? "#665AE7 !important" : "#F0EFEF !important"}
          color={filter.pos ? "#FFFFFF !important" : "#0C0C0C !important"}
          onClick={() => {
            setFilter({ ...filter, pos: !filter.pos })
          }}
        >
          <IoMdPhonePortrait size="3.5rem" />
          {isLoading ?
            <Flex flexDirection="row" paddingTop="1rem">
              <SkeletonCircle size="3" marginRight="0.4rem" />
              <SkeletonCircle size="3" marginRight="0.4rem" />
              <SkeletonCircle size="3" />
            </Flex>
            :
            <Text fontSize="4xl">
              {pageData.POS.total}
            </Text>
          }
        </Button>
        <Button
          boxShadow="md"
          flexDir="column"
          height="9rem"
          w="47%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="2xl"
          className="shadow-btn"
          background={filter.tv ? "#665AE7 !important" : "#F0EFEF !important"}
          color={filter.tv ? "#FFFFFF !important" : "#0C0C0C !important"}
          onClick={() => {
            setFilter({ ...filter, tv: !filter.tv })
          }}
        >
          <IoTvOutline size="3.5rem" colorInterpolation="black" />
          {isLoading ?
            <Flex flexDirection="row" paddingTop="1rem">
              <SkeletonCircle size="3" marginRight="0.4rem" />
              <SkeletonCircle size="3" marginRight="0.4rem" />
              <SkeletonCircle size="3" />
            </Flex>
            :
            <Text fontSize="4xl">
              {pageData.TV.total}
            </Text>
          }
        </Button>
      </Flex>

      {/* lista de equipamentos */}
      <Flex
        ref={scrollRef}
        flexDir="column"
        w="90%"
        overflowY="scroll"
        height="100vh"
        maxHeight="51vh"
        paddingRight="1rem"
        paddingLeft="1rem"
      >
        {equipaments
          .filter((eq) => (eq.tipo === 'POS' ? filter.pos : filter.tv))
          .filter((eq) => (filter.status === undefined || (eq.online === filter.status)))
          .filter((eq) => (selectedClients === undefined || selectedClients.length === 0 || selectedClients.includes(eq.clientId)))
          .filter((eq) => {
            if (!estabelecimento) return true;
            return eq.nome.toLowerCase().includes(estabelecimento.toLowerCase())
          })

          .map((eq) => (
            <Flex
              key={eq.id}
              flexDir="row"
              justifyContent="space-between"
              bg="white"
              borderRadius="md"
              mt="4"
              w="100%"
              color="black"
              textColor="#2654C5"
              boxShadow="md"
              className="shadow-equipament"
              width='100%'
            >
              <Link to={`/Equipament${eq.tipo}/${eq.id}`} >
                <Flex
                  w="1rem"
                  alignSelf="center"
                  minWidth="max-content"
                  paddingLeft="1rem"
                  bg="white"
                >

                  <img alt="logos" className="photoLittle" src={`${servers[eq.server]?.url}/api/media/favicon/`} />
                  <Text
                    fontWeight="bold"
                    fontSize="lg"
                    textColor="#44496F"
                    alignSelf="center"
                    paddingLeft="1rem"
                  >
                    {/* {eq.online.toString()} */}
                    {(eq.nome).substr(0, 18)}
                  </Text>
                </Flex>

              </Link>
              <Flex alignSelf="center">
                <Text
                  fontWeight="bold"
                  fontSize="sm"
                  textColor="black"
                  paddingRight="1rem"
                  whiteSpace="nowrap"
                >
                  {eq.tipo === "POS"
                    ? <IoMdPhonePortrait size={30} colorInterpolation="black" />
                    : <IoTvOutline size={30} colorInterpolation="black" />
                  }
                </Text>
              </Flex>
            </Flex>
          ))}
        {
          !isLoading &&
          !isLastPage &&
          !estabelecimento &&
          <UIInfiniteScroll root={scrollRef.current} fetchMore={() => { console.log("a"); nextPage() }} />
        }
        <Box marginY="3rem" />
      </Flex>
    </Flex>
  );
}
export default Equipament;
