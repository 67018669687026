import { Button, Flex, Text, Image, Box, Skeleton, SkeletonText } from "@chakra-ui/react";
import Background from "../../SVG/BackGroundAzul.svg";
// import {GoogleApiWrapper} from 'google-maps-react';

import React, { useState } from "react";
import { BsArrowReturnLeft } from "react-icons/bs";
import { Link, RouteComponentProps } from "react-router-dom";
import { useEffect } from "react";
import api_url from "../../Services/Api";
import IconeLocalizacao from "../../Assets/Icone_localizacao_nao_encontrada.png"
import ImageOrPlaceholder from "../../Components/ImageOrPlacehold";

type IPosServer = Omit<IPos,"server">&{server:IServer}

const EquipamentButton: React.FC<RouteComponentProps<{ macAddress: string }>> = ({ match }) => {
  const [pos, setPos] = useState<IPosServer>();
  const [localização, setlocalização] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchPos(mac: string) {
      const posData = await api_url.get<IPosServer>(`/pos/${mac}`).then(res => res.data);
      setPos(posData);
      setIsLoading(true);
    }

    fetchPos(match.params.macAddress);
  }, [match.params.macAddress]);

  return (
    // fundo de tudo
    <Flex
      justifyContent="center"
      flexDir="column"
      alignItems="center"
      width="100%"
    >
      <Flex zIndex={-1} position="absolute" w="100%" h="100%">
        <img
          src={Background}
          alt="background"
          style={{ width: "100vw", position: "fixed", zIndex: -1 }}
        />
      </Flex>

      <Flex alignSelf="self-end" width="100%">
        <Link to='/Equipament' replace >
          <Button
            className="Button back"
            alignSelf="left"
            w="4rem"
            colorScheme="tranparent"
            textColor="white"
            fontWeight="bold"
          >
            <BsArrowReturnLeft size={30} />
          </Button>
        </Link>
      </Flex>

      <Text textColor="white" fontWeight="bold" fontSize="3xl" p={2}>
        Equipamento
      </Text>

      <Flex w="90%" background="white" borderRadius="full">
        <Flex
          flexDir="column"
          zIndex={1}
          justifyContent="space-between"
          bg="white"
          borderRadius="3xl"
          p={3}
          textColor="#2654C5"
          boxShadow="2xl"
          w="100%"
          h="100%"
        >
          <Flex justifyContent="space-between">
            <Skeleton isLoaded={isLoading}>
              <Text textColor="#44496F" fontSize="xl" p={1} fontWeight='600'>
                POS
              </Text>
            </Skeleton>
            <Flex padding="0 0.2rem" justifyContent="space-between" alignItems="center" width="2.4rem">
              <ImageOrPlaceholder
                src={`${pos?.server.url}/api/media/favicon/`}
              />
            </Flex>
          </Flex>

          <Flex flexDir='column' marginTop='1rem'>
            <Skeleton height="1.7rem" isLoaded={isLoading}>
              <Text padding="0 0.2rem" textColor="#44496F" fontSize="xl" fontWeight='600'>
                {pos?.estabelecimento_nome}
              </Text>
            </Skeleton>
            <Skeleton height="1.7rem" isLoaded={isLoading}>
              <Text padding="0 0.2rem" textColor="#ACA5EF" fontSize="xl" fontWeight='600'>
                {/* {(pos?.server as any)?.url} */}
                {(pos?.server as any)?.url.replace("https://","").replace("."," - ").replace(".app","")}
              </Text>
            </Skeleton>
            <Skeleton height="1.4rem" isLoaded={isLoading}>
              <Flex padding="0.2rem 0.2rem" justifyContent='space-between' alignItems="flex-end">
                <Text textColor="#ACA5EF" padding="0" fontSize="0.8rem" >
                
                  {pos?.mac_address}               
                  {/* {tv?.mac_address} */}
                </Text>
              </Flex>
            </Skeleton>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        marginTop="-2rem"
        zIndex={0}
        boxShadow="2xl"
        h="100%"
        w="80%"
        borderRadius="3xl"
      >
        {localização?
        <Flex h="30rem" borderRadius="full">
          <Box borderRadius="full" h="30rem" >
            <Image borderRadius="3xl" src="https://i.ibb.co/D5Wr35C/mapa45.png" />
          </Box>
        </Flex>
        :
        <Flex h="30rem" borderRadius="1rem" background="#a5a5a5">
          <Flex flexDir="column" justifyContent="center" alignItems="center" borderRadius="full" h="30rem">
            <Image width="50%" borderRadius="3xl" paddingBottom="0.9rem" src={IconeLocalizacao} />
            <Text padding="0 1rem 1.5rem" fontSize="1.3rem" textAlign="center" color="#FFFFFF" fontWeight="bold">Localização não encontrada</Text>
          </Flex>
        </Flex>
        }
      </Flex>
    </Flex>
  );
}
export default EquipamentButton;
