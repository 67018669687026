import { Flex, Skeleton, Spinner, Stack, Text } from '@chakra-ui/react';
import format from 'date-fns/format';
import React, { useMemo } from 'react';
import ImageOrPlaceholder from '../../Components/ImageOrPlacehold';

// import { Container } from './styles';
type Props = {
  notification?: INotification;
  loading?: boolean;
  largura?: string;
}

const NotificationBox: React.FC<Props> = ({ notification, loading, largura }) => {
  // UseMemo trata os valores da notificação
  const { date, resultado, icon, server, title } = useMemo(() => {
    // valores default
    if (!notification) return {
      date: '-/-- --:--',
      resultado: 0,
      server: '',
      icon: ''
    };

    const notificationDate = notification?.body.horario_sorteio || notification?.body.data_sorteio || notification?.createdAt;
    const fmtNotificationDate = notificationDate ? format(
      new Date(notificationDate),
      "dd/MM HH:mm"
    ) : "-------";

    const resultado = notification?.body?.data?.resultado || 0;
    return {
      date: fmtNotificationDate,
      resultado,
      icon: `${notification?.serverurl}/api/media/favicon/`,
      title: `Sorteio ${notification.body.data.sorteio_id}`,
      server: notification.serverurl.replace("https://", "").replace(".app", "").replace(".", " - ")
    }
  }, [notification]);

  if (!notification && !loading) return (
    <Flex width={largura} flexDirection="column">
      <Flex
        bgColor="white"
        borderRadius="lg"
        border="dotted"
        borderColor="#706F6F"
        p={6}
        justifyContent="center"
      >
        <Flex>
          <Flex justifyContent="center" paddingRight="0.5rem">
            <Text> Nenhuma Atualização </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )


  return (
    <Flex  flexDirection="column" width={largura} className="foi">
      <Flex
        
        className="shadow-notifications"
        bgColor="white"
        borderRadius="lg"
        boxShadow="2xl"
        p={4}
        position="relative"
        justifyContent="space-between"
      >
        <Flex>
          <Flex h="48px" w="48px" justifyContent="center" marginRight="0.5rem">
            {icon ?
              <ImageOrPlaceholder
                style={{ objectFit: 'contain' }}
                alt={notification?.serverurl}
                className="photo"
                src={notification?.serverurl + "/api/media/favicon/"}
              />
              :
              <Flex align="center" alignSelf="center">
                <Spinner thickness="4px" color="blue.500" />
              </Flex>
            }
          </Flex>
          <Flex flexDir="column" justifyContent="space-around">
            <Skeleton isLoaded={!loading}>
              <Text fontWeight="bold" fontSize="l" textColor="black">
                {title}
              </Text>
            </Skeleton>
            <Skeleton isLoaded={!loading} w={loading ? "80%" : undefined} h={loading ? "30%" : undefined}>
              <Text fontSize="0.8rem" textColor="black">
                {server}
              </Text>
            </Skeleton>
          </Flex>
        </Flex>
        <Flex flexDir="column" textAlign="right" alignSelf="center">
          <Skeleton isLoaded={!loading}>
            <Text
              fontWeight="bold"
              fontSize="0.9rem"
              textColor={
                resultado > 0
                  ? "#5BE569"
                  : "#990000"
              }
            >
              {
                (resultado > 0 ? "+" : "") + resultado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
              }
            </Text>
            <Text fontSize="0.7rem" textColor="black">
              {date}
            </Text>
          </Skeleton>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default NotificationBox;