import { Button, Divider, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Wrapper } from "../wrapper";
import { MdModeEdit } from "react-icons/md";
import { IoAddCircleOutline, IoEyeSharp } from "react-icons/io5";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../Context/auth";
import api_url from "../../Services/Api";
import { InputFieldP } from "../../Components/InputFieldP";
import { useForm } from "react-hook-form";
import axios from "axios";
import Login from "../Login/Login";
import { ContentPasswordBtn, Descripition, FeedBackUserMensage } from "./Style";
import { BsEyeSlashFill } from "react-icons/bs";


// api/v1/me/subordinates
function App() {
  const { register, handleSubmit } = useForm();
  const { user } = useAuth();
  const { ChangePassword,Login } = useAuth();
  const history = useHistory();
  const [expandChangePass, setExpandChangePass] = useState(false);
 
  const [subordinates, setSubordinates] = useState<ISubordinates[]>([]);
  const [passwordVisible,setPasswordVisible] = useState(false);
  const [passwordConfirmVisible,setPasswordConfirmVisible] = useState(false);
  const [feedbackUser, setfeedbackUser] = useState("");

  

  
  type subordinatesResponse = { 
    data: ISubordinates[];
  };
  useEffect(() => {
    fetchSubordinates();

  }, []);

  

  async function fetchSubordinates() {
    const response = await api_url.get<subordinatesResponse>(
      "/users/me/subordinates"
    );
    //console.log(response.data.data);
    setSubordinates(response.data.data);
    
  }

  async function handleChangePassword(values: {password: string, passwordConfirmation: string}) {
    try {

      await ChangePassword(values.password,values.passwordConfirmation, user?.id);  
      setfeedbackUser("Senha alterada com sucesso");
    } catch (err) {
      console.log(err.response.data.message);
      setfeedbackUser(err.response.data.message);

    }

    
    // axios.put("https://hub.mrkeno.app/api/v1/users/"+ user?.id, values);
  }
  

  return (
 
    // fundo de tudo
    <Wrapper
      conf={false}
      BoxContent={
       
        <>
          {/* Topo da box branca */}
          <Flex
            className="BoxTopo"
            flexDir="column"
            justifyContent="space-between"
          >
            <Flex flexDir="row">
              <Text fontWeight="bold" fontSize="xl" >
                {user?.userName}
              </Text>
            </Flex>
            <Flex>
              <Text fontWeight="bold" fontSize="xl" textColor="#BDB7F1">
              {user?.manager == null? "Franqueado" : "Gerente"}
              </Text>
            </Flex>
          </Flex>

          {/* bottom da box branca */}
          <Flex
            className="BoxBottom"
            flexDir="column"
            justifyContent="space-between"
            marginBottom="0"
            marginTop="2rem"
            alignSelf="center"
          >
            {!expandChangePass && <Button
              textColor="white"
              bgColor="#665AE7"
              borderRadius="3xl"
              alignSelf='center'
              fontSize='2xl'
              textAlign='center'
              marginBottom='1rem'
              onClick={() => {
                setExpandChangePass(!expandChangePass);
              }}
            >
              mudar senha
            </Button>}
           
          </Flex>
          <Flex  flexDir="column" maxHeight="70vh" alignSelf='center'>
            {expandChangePass && (
              <form onSubmit={handleSubmit(handleChangePassword)}>
                <Flex flexDir='column' height="70vh"  alignSelf='center' gridGap='1rem' >
                  
                  <Text  fontSize='2xl' alignSelf='center' paddingTop='4rem' >
                    Redefinir senha
                  </Text>
                  
                  <Divider 
                    border="2px"
                    orientation="horizontal"
                    bgColor="#5247d0" 
                    borderColor="#5247d0"
                    margin="1rem 0rem"
                  />
                  <InputFieldP
                    id="password"
                    type={passwordVisible? "text":"password"}
                    placeholder="Senha"
                    borderRadius='full'
                    {...register("password")}
                  />
                  <InputFieldP
                    id="passwordConfirm"
                    type={passwordConfirmVisible? "text":"password"}
                    placeholder="Confirmar senha"
                    borderRadius='full'
                    {...register("passwordConfirmation")}
                  />

                      <ContentPasswordBtn 
                          type="button"
                          display={passwordVisible? "flex":"none"}
                          onClick={() => {
                            setPasswordVisible(!passwordVisible)
                            setPasswordConfirmVisible(!passwordConfirmVisible)
                          }}>
                              <BsEyeSlashFill />
                      </ContentPasswordBtn>
                      <ContentPasswordBtn 
                        type="button"
                        display={passwordVisible? "none":"flex"}
                        onClick={() => {
                          setPasswordVisible(!passwordVisible)
                          setPasswordConfirmVisible(!passwordConfirmVisible)
                        }}>
                      <IoEyeSharp />
                      </ContentPasswordBtn>
                  <Flex  textAlign='center' alignSelf='center' flexDir='column' >

                    <FeedBackUserMensage>
                      {feedbackUser}
                    </FeedBackUserMensage>
                      

                    <Button
                      textColor="white"
                      bgColor="#665AE7"
                      borderRadius="3xl"
                      fontSize='2xl'
                      type="submit"
                    >
                      mudar senha
                    </Button>

                    <Flex alignSelf='center'>
                      <Button
                        
                        display="flex"
                        variant="ghost"
                        textColor='#BDB7F1'
                        fontSize='lg'
                        onClick={() => {
                          setExpandChangePass(!expandChangePass);
                        }}
                      >
                        cancelar
                      </Button>
                      
                    </Flex>
                  </Flex>
                </Flex>
              </form>
            )}
          </Flex>
          
        </>
      }
      
    >
      {/* //box branca */}
      <Flex
        flexDir="column"
        w="100%"
        overflow="auto"
        marginTop="1rem"
        padding="0 1.5rem"
        overflowY="scroll"
        height="100vh"
        maxHeight="51vh"
      >
        {subordinates.map((subordinate, indice) => {
          return (
            <Link to={"/UsersSelect/" + subordinate.id}>
            <Button
              key={subordinate.id}
              bgColor="white"
              justifyContent="space-between"
              alignSelf="center"
              borderRadius="xl"
              mt="2"
              w="100%"
              p={1}
              paddingEnd="0.5rem"
              paddingStart="0.5rem"
              color="black"
              textColor="#2654C5"
              boxShadow="md"
            >
              <Text
                fontWeight="bold"
                fontSize="mg"
                textColor="#44496F"
                alignSelf="center"
              >
                {subordinate.userName}
              </Text>
              <Text
                fontWeight="bold"
                fontSize="xl"
                textColor="#665AE7"
                alignSelf="center"
                paddingLeft="1"
              >
                
                <Button
                  bgColor="#665AE7"
                  borderRadius="full"
                  size="10"
                  fontSize="lg"
                  h="1.5rem "
                  w="1.5rem"
                >
                
                    <MdModeEdit color="white"></MdModeEdit>
                  
                </Button>
              </Text>
            </Button>
            </Link>
          );
        })}
      </Flex>
      
      <Flex position="absolute" width="100%" bottom="0" justifyContent="flex-end" padding="1rem 1.5rem">
        <Button
          fontSize="4xl"
          borderRadius="full"
          size="100"
          bgColor="#665AE7"
          textColor="white"
          h="3rem "
          w="3rem"
          disabled={user?.manager == null? false : true}
          display={user?.manager == null? "flex" : "none"}
        >
          <Link to="/UsersNew">
          {/* {user?.manager == null? "Franqueado" : "Gerente"} */}
            <Flex>
              <IoAddCircleOutline/>
            </Flex>
          </Link>
        </Button>
      </Flex>
    </Wrapper>
  );
}
export default App;
