import styled from "@emotion/styled";


// FINANCEIRO
export const FlexFilter = styled.div`
   
    flex-direction:column;
    z-index:4;
    justify-content:space-between;
    background:white;
    border-radius:15px;
    
    padding:1rem;
    color:#2654C5;
    width:100%;
    height:100%;
    display: flex;


`;

export const GenericValueList = styled.div`
   
    flex-direction:column;
    width:"100%";
    display: flex;

    height: 37vh;
    overflow: scroll;

`;


export const ButtonResume = styled.button<{ bg: string }>`
    justify-content:space-between;
    border-radius:0.8rem;
    box-shadow: 0px 3px 2px 0px rgba(0.2, 0, 0, 0.2);
    background:${props => props.bg};
    transition:"0.8s";
    display: flex;
    margin-bottom: 0.5rem;
    padding: 0.5rem 1rem;
    width: 100%;
    
    
`;

export const TextResumeTitle = styled.span<{ color: string }>`

    font-weight:"bold";
    font-size:"sm";
    transition:"0.8s";
    color:${props => props.color};
    text-align: left;
    /* padding-left: 1rem; */
  
`;

export const FlexResumeAndServer = styled.div`
    justify-content:center;
    border-radius:1rem;
    box-shadow:lg;
    background :white;
    width:100%;
    display: flex;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.3) !important;
`;

export const ServerListBox = styled.div`
    min-height: 3rem;
    margin: 0;
    padding: 0;
    background: transparent;
    justify-content: center;
    align-items: center;
    display: flex;

`;

export const ServerListBoxAll = styled.div`
    position: fixed;
    bottom: 0;
    margin: 0;
    padding: 0.7rem 0;
    background: #FFFFFF;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
`;

export const TextValueServerFinancial = styled.span`

    margin-left:0;
    font-weight:bold;
    font-size:sm;
  
`;1

export const ButtonServer = styled.button<{ bg: string }>`
    /* onClick */
    /* key */
    background:${props => props.bg};
    flex-direction:"row";
    align-self:"center";
    border-radius: 0.8rem;
    padding: 0.3rem 0.8rem 0.1rem;
    /* mt:"2" */
    width:"90%";
    color:"black";
    box-shadow: 0px 3px 2px 0px rgba(0.2, 0, 0, 0.2);
    transition: "0.5s";
    /* textColor:"#2654C5" */
    /* boxShadow:"md" */
    /* transition:"0.5s"; */
    width:90%;
    margin-bottom:0.7rem;

`;

export const ButtonServerAll = styled.button<{ bg: string }>`
    background:${props => props.bg};
    flex-direction:"row";
    align-self:"center";
    border-radius: 0.8rem;
    padding: 0rem 0.8rem;
    color:"black";
    box-shadow: 0px 3px 2px 0px rgba(0.2, 0, 0, 0.2);
    transition: "0.5s";
    width:50%;
`;

export const CicleOnlineFinancial = styled.div<{bg:string}>`
    width: 0.7rem;
    height: 0.7rem;

    top: 0.2rem;
    right:0.2rem;
    position: absolute;

    background: ${props => props.bg};
    border-radius: 50%;
`;