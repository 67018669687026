import styled from "@emotion/styled";

export const CicleOnline = styled.div<{bg:string}>`
    width: 0.7rem;
    height: 0.7rem;

    margin-right: 1rem;

    background: ${props => props.bg};
    border-radius: 50%;
`;

export const Container = styled.div<{blur:string}>`
    display:flex;

    width: 100%;
    height: 100%;

    position: fixed;

    z-index: 10;
    filter: blur(${props => props.blur});
`;

export const ContentNotifications = styled.div`
    display: flex;
    flex-direction: column;

    width:100%;
    max-height: 32rem; 
    z-index:3;

    overflow: scroll;
    position:absolute;

    bottom:0.5rem;
    padding:0.5rem 1.8rem 0.5rem 1.8rem;

    filter: blur(0);
`;

export const ContainerList = styled.div`
    width:calc(100% - 3.2rem);
    padding: 0rem 1.8rem 0rem 1.8rem;
    max-height:85%;
    min-height:80%;

    flex-direction:column; 

    position:relative;

    overflow: scroll;

    box-sizing: content-box;
`;


export const ContentNotificationNumber = styled.div`
    width:2rem;
    height:2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    font-size: 0.7rem;
    font-weight: 900;
    background: #665AE7;

    border-radius: 50%;
`;