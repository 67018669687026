import {
  format,
  subDays,
  getDay,
  startOfMonth,
  differenceInMonths,
} from "date-fns";
import {
  Button,
  ButtonProps,
  Flex,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsArrowReturnLeft } from "react-icons/bs";
import { GiSwapBag } from "react-icons/gi";
import { BiTrophy } from "react-icons/bi";
import { MdAttachMoney } from "react-icons/md";
import Calendar from "react-calendar";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { FiFilter } from "react-icons/fi";
import { Link } from "react-router-dom";
import { CalendarIcon } from "@chakra-ui/icons";
import api_url from "../../Services/Api";
import {
  ButtonResume, ButtonServer,
  ButtonServerAll, CicleOnlineFinancial, FlexFilter, FlexResumeAndServer,
  GenericValueList, ServerListBox, ServerListBoxAll,
  TextResumeTitle,
  TextValueServerFinancial
} from "./Style";
import { MenuButtonText } from "../../Components/MenuButtonText";
import { MenuButton } from "../../Components/HorizontalButtons";
import { conversaoMonetaria, conversaoNumeros, conversaoNumerosMilhar } from "../../Components/TratamentoDados";
import ImageOrPlaceholder from "../../Components/ImageOrPlacehold";
import { truncateSync } from "fs";

const mrKeno = "https://devs1.supersorte.app/api/media/login/";



function App() {
  const [dateStart, setDateStart] = useState(
    subDays(new Date(), getDay(new Date())-1)
  );
  const [dateEnd, setDateEnd] = useState(new Date());
  const [expandFilter, setExpandFilter] = useState(false);
  const [expandServer, setExpandServer] = useState(true);
  const [expandResume, setExpandResume] = useState(false);
  const [selectedResumo, setSelectedResumo] = useState(false);
  const [selectedServidores, setSelectedServidores] = useState(true);
  const [selectedDay, setSelectedDay] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(false);
  const [calendarInput, setCalendarInput] = useState(false);
  const [value, setValue] = useState(new Date());

  //Financeiro
  const [financialResume, setFinancialResume] = useState<ITotalSemanal>();
  const [financialTotal, setFinancialTotal] = useState<IFinancial[]>([]);


  const [selectServer, setSelectServer] = useState<string>("/");
  const [dataValueTotal, setDataValueTotal] = useState<IGraphics[]>([]);
  const [clientes, setClientes] = useState<IClient[]>([]);
  const [selectedClients, setSelectedClients] = useState<string[]>([])



  //const valores para habilitar/desabilitar gráfico
  const [doacoes, setDoacoes] = useState(true);
  const [premios, setPremios] = useState(false);
  const [saldo, setSaldo] = useState(false);
  const [comissao, setComissao] = useState(false);
  const [resultado, setResultado] = useState(false);
  const [sorteios, setSorteios] = useState(false);
  const [doacoesBilhetes, setDoacoesBilhetes] = useState(false);
  const [bilhetesVendidos, setBilhetesVendidos] = useState(false);


  const [isLoading, setIsLoading] = useState(true);
  const [dataStartCalendar, setDataStartCalendar] = useState(new Date());
  const [dataEndCalendar, setDataEndCalendar] = useState(new Date());
  const [dataDifferenceCalendar, setDataDifferenceCalendar] = useState(0);



  //gráfico
  const dataRequisicao = new Date();
  //Referente a semana no gráfico
  //
  const ultimoDomingo = subDays(dataRequisicao, getDay(dataRequisicao)-1);
  //console.log(ultimoDomingo);
  //Referente ao mes no gráfico
  const ultimoMes = new Date(startOfMonth(dataRequisicao));

  const renderLineChart = (
    <LineChart
      width={320}
      height={200}
      data={dataValueTotal}
      // dataValue
      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="data" />
      <YAxis />
      <Tooltip />
      <Legend iconType="plainline" layout="horizontal" iconSize={0} />

      {doacoes && <Line type="natural" dataKey="doacoes" stroke="#4169E1" />}
      {premios && <Line type="natural" dataKey="premios" stroke="#5F9EA0" />}
      {saldo && <Line type="natural" dataKey="saldo" stroke="#66CDAA" />}
      {comissao && <Line type="natural" dataKey="comissao" stroke="#20B2AA" />}
      {resultado && ( <Line type="natural" dataKey="resultado" stroke="#9370DB"/>)}
      {sorteios && <Line type="natural" dataKey="sorteios" stroke="#7B68EE" />}
      {doacoesBilhetes && ( <Line type="natural" dataKey="Ticket Medio" stroke="#00BFFF" />)}
      {bilhetesVendidos && <Line type="natural" dataKey="bilhetes" stroke="#494274" />}
    </LineChart>
  );

  type ServerlTotal = {
    data: IFinancial[];
    total: IFinancialResume;
  };
  type GraphicsTotal = {
    // total: IGraphics[];
    data: IGraphics[];
    total: ITotalSemanal;
  };

  async function fetchTotalDATE(startDate?: string, endDate?: string) {
    const response = await api_url.get<ServerlTotal>(
      "/reports/financial", {
      params: {
        startTime: startDate,
        endTime: endDate,
        client: selectedClients,
      }
    }
    );
    //para os servidores
    setFinancialTotal(response.data.data);
  }

  //Coletando dados
  async function fetchTotalGraphics(startDate?: string, endDate?: string, selectServer?: string) {

    const response = await api_url.get<GraphicsTotal>(
      `/reports/financial${!selectServer ? "" : `/${selectServer}`}`, {
      params: {
        startTime: startDate,
        endTime: endDate,
        daily: true,
        client: selectedClients,
      }
    }
    );
    //para o total
    //console.log("Valor:");
    setFinancialResume(response.data.total);
    //setFinancialTotal(response.data.total)

    //formatando os valores das datas 
    const dataFormatada = response.data.data.map(x => (
      {
        ...x,
        dias: x?.dias?.map(dia => ({ ...dia, data: dia.data.split("-")[2] }))
      }
    ));


    const valorInicial = {
      doacoes: 0,
      premios: 0,
      saldo: 0,
      comissao: 0,
      resultado: 0,
      sorteios: 0,
      "Ticket Medio": 0,
      bilhetes: 0,
    };
    //soma dos valores com base dos dias selecionados
    // console.log("data antes da soma");

    const somaValores = dataFormatada?.reduce((acc, data) => {

      data.dias?.map((dias, indice) => {

        if (!acc[indice]) acc[indice] = { ...valorInicial };
        acc[indice].data = dias.data;
        acc[indice].doacoes = acc[indice].doacoes + dias.doacoes;
        acc[indice].premios = acc[indice].premios + dias.premios;
        acc[indice].saldo = acc[indice].saldo + dias.saldo;
        acc[indice].comissao = acc[indice].comissao + dias.comissao;
        acc[indice].resultado = acc[indice].resultado + dias.resultado;
        acc[indice].sorteios = acc[indice].sorteios + dias.sorteios;
        acc[indice]["Ticket Medio"] = (acc[indice]["Ticket Medio"] || 0) + dias["doacoes/bilhetes"] || 0;
        acc[indice].bilhetes = acc[indice].bilhetes + dias.bilhetes;
      })

      return acc;
    }, [] as any);


    setDataValueTotal(somaValores);

  }
  // console.log("data depois da soma");
  // console.log(sumData);

  async function fetchCarregando() {
    await fetchTotalDATE(
      format(dateStart, "dd-MM-yyyy"),
      format(dateEnd, "dd-MM-yyyy")
    );
    setIsLoading(false);
  }



  useEffect(() => {
    fetchCarregando();
    //console.log(selectedClients)
  }, [dateStart, dateEnd, selectedClients]);

  useEffect(() => {
    async function fetchClient() {
      type apiData = { data: IClient[] };
      const response = await api_url.get<apiData>("/users/me/clients");
      setClientes(response.data.data);
    }
    ValorGraphics(dateStart, dateEnd, "/");

    fetchClient();
  }, []);


  function ValorGraphics(fistDate: Date, secondDate: Date, selectServer: string) {
    fetchTotalGraphics(
      format(fistDate, "dd-MM-yyyy"),
      format(secondDate, "dd-MM-yyyy"),
      selectServer
    );
    //console.log();
  }

  return (
    // fundo de tudo

    <Flex justifyContent="center" flexDir="column" w="100%" zIndex={5}>
      {calendarInput && (
        <Flex
          id="fora"
          flexDirection="column"
          onClick={(e) => {
            if ((e.target as Element).id === "fora") {
              setCalendarInput(false);
            }
          }}
          height="100vh"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          position="absolute"
          zIndex="5"
          background="rgba(40, 44, 52, 0.50)"
        >
          <Calendar
            className="calendar-filter"
            selectRange={true}
            defaultValue={[dataStartCalendar, dataEndCalendar]}
            onChange={(value: any, e) => {
              e.stopPropagation();
              setValue(value);
              // {(pos?.server as any)?
              setDateStart(value[0]);
              setDateEnd(value[1]);
              ValorGraphics(value[0], value[1], "");
              setDataStartCalendar(value[0]);
              setDataEndCalendar(value[1]);
              setDataDifferenceCalendar(differenceInMonths(value[0], value[1]));
            }}
            value={value}
          />
          <Flex
            width="90%"
            background="#FFFFFF"
            padding="0.5rem 0.2rem 0.5rem"
            fontWeight="bold"
            flexDirection="column"
          >
            <Flex padding="0.3rem 1.5rem" minHeight="1.5rem" maxHeight="1.5rem">
              {dataDifferenceCalendar < -29 ? (
                <Flex padding="0.2rem 0.7rem" borderRadius="0.2rem">
                  <Text
                    width="100%"
                    color="#e42a2a"
                    fontSize="0.9rem"
                    textAlign="center"
                  >
                    Selecione um periodo de até um mês.
                  </Text>
                </Flex>
              ) : (
                ""
              )}
            </Flex>
          </Flex>
          <Flex
            width="90%"
            justifyContent="center"
            background="#FFFFFF"
            borderRadius="0 0 0.4rem 0.4rem"
            padding="0.6rem 0.4rem 0.3rem"
          >
            {dataDifferenceCalendar < -29 ? (
              <Button
                background="#665AE7"
                disabled
                color="#FFFFFF"
                onClick={() => {
                  setCalendarInput(false);
                }}
              >
                confirmar
              </Button>
            ) : (
              <Button
                background="#665AE7"
                color="#FFFFFF"
                onClick={() => {
                  setCalendarInput(false);
                }}
              >
                confirmar
              </Button>
            )}
          </Flex>
        </Flex>
      )}
      {/*  button back*/}
      <Link to="/Main">
        <Button
          className="Button back"
          // selfAlign="left"
          w="4rem"
          colorScheme="tranparent"
          textColor="black"
          fontWeight="bold"
        // onClick={history.goBack}
        >
          <BsArrowReturnLeft size={30} />
        </Button>
      </Link>

      {/* Topo da página */}
      <Flex justifyContent="space-between" p={4}>
        <Text fontWeight="bold" fontSize="2xl" textColor="#2654C5">
          Financeiro
        </Text>

        <Button
          bgColor="#665AE7"
          borderRadius="full"

          size="10"
          fontSize="1rem"
          h="1.5rem "
          w="1.5rem"
          onClick={() => {
            setExpandFilter(!expandFilter);
          }}
        >
          <FiFilter color="white" />
        </Button>
      </Flex>
      {/* Botão do filtro */}

      <Flex justifyContent="center" p={1}>
        {expandFilter && (
          <Flex position="absolute" zIndex="4" w="90%" background="white">
            <FlexFilter className="shadow-equipament">
              <Text textColor="black" fontSize="lg">
                Clientes:
              </Text>

              <Flex>
                {clientes.map((cliente, indice) => {
                  return (
                    <Flex key={cliente.id} flexDir="row" p={2}>
                      <Button
                        bg={selectedClients.includes(cliente?.id) ? "#665AE7 !important" : "#F0EFEF !important"}
                        onClick={() => {
                          if (selectedClients.includes(cliente?.id))
                            setSelectedClients(selectedClients.filter(id => id !== cliente.id));
                          else
                            setSelectedClients([...selectedClients, cliente.id]);
                        }
                        }

                      >
                        <img
                          src={`https://001.${cliente.baseDomain}/api/media/favicon/`}
                          alt={cliente.clientName}
                          width="30rem"
                        />
                      </Button>
                    </Flex>
                  );
                })}
              </Flex>
            </FlexFilter>
          </Flex>
        )}
      </Flex>

      {/* Gráfico */}
      <Flex zIndex={2} alignSelf="center">
        {renderLineChart}
      </Flex>

      {/* botões de filtro */}
      <Flex flexDir="row" justifyContent="space-between" p={4}>
        <MenuButtonText
          onClick={() => {
            setDateStart(new Date());
            setDateEnd(new Date());
            ValorGraphics(new Date(), new Date(), selectServer);
            setSelectedDay(true);
            setSelectedWeek(false);
            setSelectedMonth(false);
            setSelectedFilter(false);
          }}
          background={
            selectedDay ? "#665AE7 !important" : "transparent !important"
          }
          color={selectedDay ? "#FFFFFF" : "#665AE7"}
          transform={selectedDay ? "scale(1.05)" : "none"}
          to="/Financial"
          icon={<CalendarIcon />}
        >
          D
        </MenuButtonText>
        <MenuButtonText
          onClick={() => {
            setDateStart(ultimoDomingo);
            setDateEnd(dataRequisicao);
            ValorGraphics(ultimoDomingo, dataRequisicao, selectServer);
            //console.log("DATA DE UM SEMANAL, " + dateStart + " " + dateEnd);

            setSelectedDay(false);
            setSelectedWeek(true);
            setSelectedMonth(false);
            setSelectedFilter(false);
          }}
          background={
            selectedWeek ? "#665AE7 !important" : "transparent !important"
          }
          color={selectedWeek ? "#FFFFFF" : "#665AE7"}
          transform={selectedWeek ? "scale(1.05)" : "none"}
          to="/Financial"
          icon={<CalendarIcon />}
        >
          S
        </MenuButtonText>
        <MenuButtonText
          onClick={() => {
            setDateStart(ultimoMes);
            setDateEnd(dataRequisicao);
            ValorGraphics(ultimoMes, dataRequisicao, selectServer);
            //console.log("DATA DE UM MES, " + dateStart + " " + dateEnd);

            setSelectedDay(false);
            setSelectedWeek(false);
            setSelectedMonth(true);
            setSelectedFilter(false);
          }}
          background={
            selectedMonth ? "#665AE7 !important" : "transparent !important"
          }
          color={selectedMonth ? "#FFFFFF" : "#665AE7"}
          transform={selectedMonth ? "scale(1.05)" : "none"}
          to="/Financial"
          icon={<CalendarIcon />}
        >
          M
        </MenuButtonText>
        <MenuButton
          background={
            selectedFilter ? "#665AE7 !important" : "transparent !important"
          }
          color={selectedFilter ? "#FFFFFF !important" : "#665AE7 !important"}
          transform={selectedFilter ? "scale(1.05)" : "none"}
          onClick={() => {
            setCalendarInput(true);
            setSelectedDay(false);
            setSelectedWeek(false);
            setSelectedMonth(false);
            setSelectedFilter(true);
          }}
          to="/Financial"
          icon={
            <CalendarIcon
              color={
                selectedFilter ? "#FFFFFF !important" : "#665AE7 !important"
              }
            />
          }
        ></MenuButton>
        { }
      </Flex>

      {/* Buttons resume and servers */}
      <Flex p={4} justifyContent="center">
        <FlexResumeAndServer>
          <Button
            fontSize="md"
            background="#"
            size="100"
            h="1.8rem "
            transition="0.8s"
            w={selectedServidores ? "55%" : selectedResumo ? "45%" : "50%"}
            borderRadius={selectedServidores ? "full" : "full full 0 0"}
            color={selectedServidores ? "#FFFFFF" : "#0c0c0c"}
            bgColor={
              selectedServidores ? "#665AE7 !important" : "transparent !important"
            }
            onClick={() => {
              setExpandResume(false);
              setExpandServer(true);
              setSelectedResumo(false);
              setSelectedServidores(true);
            }}
          >
            <Text> SERVIDORES</Text>
          </Button>
          <Button
            fontSize="md"
            size="100"
            h="1.8rem"
            transition="0.8s"
            w={selectedResumo ? "55%" : selectedServidores ? "45%" : "50%"}
            borderRadius={selectedResumo ? "full" : "full full 0 0"}
            color={selectedResumo ? "#FFFFFF" : "#0c0c0c"}
            bgColor={
              selectedResumo
                ? "#665AE7 !important"
                : "transparent !important"
            }
            onClick={() => {
              setExpandServer(false);
              setExpandResume(true);
              setSelectedResumo(true);
              setSelectedServidores(false);
            }}
          >
            <Text>RESUMO</Text>
          </Button>
        </FlexResumeAndServer>
      </Flex>
      {/* lists */}
      {expandResume && (
        <Flex className="ExpandResume" flexDir="column" maxHeight="65vh" p={2}>

          <Flex flexDir="column" overflowY="scroll" maxHeight="20rem">
            <Flex
              flexDir="column"
              alignSelf="center"
              // bg="white"
              mt="2"
              w="95%"
              textColor="#2654C5"
              
            >
              <ButtonResume
                onClick={() => setDoacoes(!doacoes)}
                bg={doacoes ? "#665AE7 !important" : "white !important"}
              >
                <TextResumeTitle color={doacoes ? "#FFFFFF" : "#44496F"}>
                  {"Doações"}
                </TextResumeTitle>
                <Skeleton isLoaded={!isLoading}>
                  <Text fontWeight="bold" fontSize="0.9rem" textColor="#5BE569">
                    {" "}
                    {(financialResume?.doacoes || 0).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Text>
                </Skeleton>
              </ButtonResume>

              <ButtonResume
                onClick={() => setPremios(!premios)}
                bg={premios ? "#665AE7 !important" : "transparent !important"}
              >
                <TextResumeTitle color={premios ? "#FFFFFF" : "#44496F"}>
                  {"Prêmios"}
                </TextResumeTitle>
                <Skeleton isLoaded={!isLoading}>
                  <Text fontWeight="bold" fontSize="sm" textColor="#5BE569">
                    {" "}
                    {conversaoMonetaria(financialResume?.premios || 0)}
                  </Text>
                </Skeleton>
              </ButtonResume>

              <ButtonResume
                onClick={() => setSaldo(!saldo)}
                bg={saldo ? "#665AE7 !important" : "transparent !important"}
              >
                <TextResumeTitle color={saldo ? "#FFFFFF" : "#44496F"}>
                  {"Saldo"}
                </TextResumeTitle>
                <Skeleton isLoaded={!isLoading}>
                  <Text fontWeight="bold" fontSize="sm" textColor="#5BE569">
                    {" "}
                    {conversaoMonetaria(financialResume?.saldo || 0)}
                  </Text>
                </Skeleton>
              </ButtonResume>

              <ButtonResume
                onClick={() => setComissao(!comissao)}
                bg={comissao ? "#665AE7 !important" : "transparent !important"}
              >
                <TextResumeTitle color={comissao ? "#FFFFFF" : "#44496F"}>
                  {"Comissão"}
                </TextResumeTitle>
                <Skeleton isLoaded={!isLoading}>
                  <Text fontWeight="bold" fontSize="sm" textColor="#5BE569">
                    {conversaoMonetaria(financialResume?.comissao || 0)}
                  </Text>
                </Skeleton>
              </ButtonResume>

              <ButtonResume
                onClick={() => setResultado(!resultado)}
                bg={resultado ? "#665AE7 !important" : "transparent !important"}
              >
                <TextResumeTitle color={resultado ? "#FFFFFF" : "#44496F"}>
                  {"Resultado"}
                </TextResumeTitle>
                <Skeleton isLoaded={!isLoading}>
                  <Text fontWeight="bold" fontSize="sm" textColor="#5BE569">
                    {" "}
                    {conversaoMonetaria(financialResume?.resultado || 0)}
                  </Text>
                </Skeleton>
              </ButtonResume>

              <ButtonResume
                onClick={() => setSorteios(!sorteios)}
                bg={sorteios ? "#665AE7 !important" : "transparent !important"}
              >
                <TextResumeTitle color={sorteios ? "#FFFFFF" : "#44496F"}>
                  {"Sorteios"}
                </TextResumeTitle>
                <Skeleton isLoaded={!isLoading}>
                  <Text fontWeight="bold" fontSize="sm" textColor="#5BE569">
                    {financialResume?.sorteios || 0}
                  </Text>
                </Skeleton>
              </ButtonResume>

              <ButtonResume
                onClick={() => setDoacoesBilhetes(!doacoesBilhetes)}
                bg={
                  doacoesBilhetes
                    ? "#665AE7 !important"
                    : "transparent !important"
                }
              >
                <TextResumeTitle color={doacoesBilhetes ? "#FFFFFF" : "#44496F"}>
                  {"Ticket Medio"}
                </TextResumeTitle>
                <Skeleton isLoaded={!isLoading}>
                  <Text fontWeight="bold" fontSize="sm" textColor="#5BE569">
                    {" "}
                    {conversaoNumerosMilhar(financialResume?.["doacoes/bilhetes"] || 0)}
                  </Text>
                </Skeleton>
              </ButtonResume>

              <ButtonResume
                onClick={() => setBilhetesVendidos(!bilhetesVendidos)}
                bg={bilhetesVendidos ? "#665AE7 !important" : "white !important"}
              >
                <TextResumeTitle color={bilhetesVendidos ? "#FFFFFF" : "#44496F"}>
                  {"Bilhetes vendidos"}
                </TextResumeTitle>
                <Skeleton isLoaded={!isLoading}>
                  <Text fontWeight="bold" fontSize="0.9rem" textColor="#5BE569">
                    {" "}
                    {conversaoNumeros(financialResume?.bilhetes || 0)}
                  </Text>
                </Skeleton>
              </ButtonResume>


            </Flex>
          </Flex>

        </Flex>
      )}
      {/* servidores */}
      {expandServer && (
        <Flex flexDir="column" minHeight="18rem" maxHeight="18rem">
          <GenericValueList>

            {financialTotal?.map((financialT,) => {
              return (
                <ServerListBox key={"slist" + financialT?.server?.id}>
                  <ButtonServer
                    onClick={() => {
                      setSelectServer(financialT?.server?.id);
                      ValorGraphics(dateStart, dateEnd, financialT.server?.id);

                    }}
                    bg={
                      selectServer === financialT?.server?.id
                        ? "#665AE7 !important"
                        : "white !important"
                    }

                  >
                    <Flex flexDir="row" w="100%" position="relative">
                    {financialT?.online === true &&
                      <CicleOnlineFinancial bg="#5BE569"/>
                    }
                    {financialT?.online === false &&
                      <CicleOnlineFinancial bg="#990000"/>
                    }
                    {financialT?.online === undefined &&
                      <CicleOnlineFinancial bg="#aaaaaa"/>
                    }
                      <Flex>
                        <ImageOrPlaceholder
                          alt={financialT?.server?.serverName}
                          className="photoLittle"
                          src={financialT?.server?.url + "/api/media/favicon/"}
                        />
                      </Flex>
                      <Flex flexDir="column" w="100%" marginLeft="1rem">
                        <TextResumeTitle color={selectServer === financialT?.server?.id ? "white !important" : "#665AE7 !important"}
                        >
                          {financialT?.server?.serverName != null? financialT?.server?.serverName : "Servidor offline"}
                        </TextResumeTitle>

                        <Flex
                          w="100%"
                          justifyContent="space-between"
                          transition="0.2s"
                          textColor={
                            selectServer === financialT?.server?.id
                              ? "white !important"
                              : "black !important"
                          }
                        >
                          <Flex>
                            <Flex
                              textColor={
                                selectServer === financialT?.server?.id
                                  ? "white !important"
                                  : "#665AE7 !important"
                              }
                            >
                              <GiSwapBag />
                            </Flex>

                            <TextValueServerFinancial>
                              {(financialT?.doacoes || 0).toLocaleString(
                                "pt-BR"
                              )}

                            </TextValueServerFinancial>
                          </Flex>
                          <Flex>
                            <Flex
                              textColor={
                                selectServer === financialT?.server?.id
                                  ? "white !important"
                                  : "#665AE7 !important"
                              }
                            >
                              <BiTrophy />
                            </Flex>
                            <TextValueServerFinancial>
                              {(financialT?.premios || 0).toLocaleString(
                                "pt-BR"
                              )}
                            </TextValueServerFinancial>
                          </Flex>
                          <Flex>
                            <Flex
                              textColor={
                                selectServer === financialT.server?.id
                                  ? "white !important"
                                  : "#665AE7 !important"
                              }
                            >
                              <MdAttachMoney />
                            </Flex>
                            <TextValueServerFinancial>
                              {(financialT?.resultado || 0).toLocaleString("pt-BR")}
                            </TextValueServerFinancial>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </ButtonServer>
                </ServerListBox>

              );
            })}

          </GenericValueList> 
        </Flex>
      )}
    {expandServer && (
      <ServerListBoxAll>
        <ButtonServerAll
          onClick={() => {
            setSelectServer("/");
            ValorGraphics(dateStart, dateEnd, "/");

          }}
          bg={selectServer === "/" ? "#665AE7 !important" : "white !important"}
        >
          <Flex flexDir="row" w="100%">
            <Flex flexDir="column" w="100%">
              <Text
                fontWeight="bold"
                fontSize="1rem"
                transition="0.5s"
                padding="0.1rem"
                textColor={selectServer === "/" ? "white !important" : "#665AE7 !important"}
              >
                Todos
              </Text>
            </Flex>
          </Flex>
        </ButtonServerAll>
      </ServerListBoxAll>
    )}
    </Flex>
  );
}
export default App;
