import React, {  useState } from 'react'
import { SmallAddIcon, MinusIcon } from "@chakra-ui/icons";
import { Flex, Divider, Box, Button, Text, Skeleton } from '@chakra-ui/react';
import { api_url } from '../../Services/Api';
import { CicleOnline } from './Style';

const newDate = new Date();
const dateToday =
  newDate.getDate() +
  ", " +
  newDate.toLocaleString("pt-BR", { month: "short" });


export type PartialResponse = {
  data: IServer[];
  total: number;
  serve_time: string;
};


export default function MainBox({ partials }: { partials?: PartialResponse }) {
  const [expand, setExpand] = useState(false);


  return <>
    <Flex justifyContent="space-between" >
      <Flex flexDir="column">
        <Text fontWeight="bold" fontSize="xl" textColor="#665AE7">
          {" "}
          Parcial do dia{" "}
        </Text>
      </Flex>
      <Flex>
        <Text fontWeight="bold" fontSize="xl" textColor="#BDB7F1">
          {" "}
          {dateToday}{" "}
        </Text>
      </Flex>
    </Flex>

    <Divider
      border="1px"
      orientation="horizontal"
      bgColor="#665AE7"
      borderColor="#665AE7"
    />

    {/* bottom da box branca */}
    <Flex
      className="BoxBottom"
      flexDir="column"
      justifyContent="space-between"
      marginBottom="0"
      marginTop="3.5rem"
    >
      <Flex flexDir="row" justifyContent="space-between">
        <Box>
          <Text lineHeight="1.5rem" fontWeight="bold" fontSize="2xl" textColor="#BDB7F1">
            R$
          </Text>
          <Skeleton isLoaded={!!partials} w="190%" h="50%">
            <Text lineHeight="2.7rem" fontWeight="bold" fontSize="4xl" textColor="#665AE7">
              {(partials?.total || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
            </Text>
          </Skeleton>
        </Box>

        {/* botão expandir */}
        {!expand && (
          <Button
            flexDir="column"
            display="flex"
            variant="ghost"
            width="1rem"
            height="2rem"
            className="aqui"
            onClick={() => {
              setExpand(!expand);
            }}
            disabled={!partials}
            justifyContent="center"
            alignSelf="flex-end"
            padding="0"
            alignItems="flex-end"
          >
            <SmallAddIcon boxSize={8} color="#665AE7" w={6} h={6} />
          </Button>
        )}
      </Flex>
    </Flex>
    {expand && (
      <Flex flexDir="column" position="relative" zIndex={10} maxHeight="60vh" padding="1rem 0 2.5rem 0">
        <Flex
          className="XABLAU"
          flexDir="column"
          zIndex={10}
          w="100%"
          justifyContent="center"
          overflow="auto"
          maxHeight="25rem"
        >
          {partials?.data?.map(({ resultado, serverName, serverId, online }) => {
            return (
              <Flex
              className="foi"
                key={serverId}
                flexDir="row"
                justifyContent="space-between"
                alignSelf="center"
                bg="white"
                borderRadius="xl"
                mb="2"
                w="100%"
                p={2}
                color="black"
                textColor="#2654C5"
                boxShadow="md"
              >
                <Flex flexDir="row" alignItems="center">
                {online == true &&
                  <CicleOnline bg="#5BE569"/>
                }
                {online == false &&
                  <CicleOnline bg="#990000"/>
                }
                 {online == undefined &&
                  <CicleOnline bg="#aaaaaa"/>
                }
                  <Text fontWeight="bold" fontSize="sm" textColor="#44496F">
                    {serverName}
                  </Text>
                </Flex>
                <Text
                  fontWeight="bold"
                  fontSize="sm"
                  textColor={resultado >= 0 ? "#5BE569"
                    : "#990000"}
                >
                  {resultado >= 0 ? "+" + resultado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : resultado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Text>
              </Flex>
            );
          })}
          <Flex className="shadowScroll" justifyContent="flex-end" position="absolute" bottom="0" width="100%">
            <Button
              height="2rem"
              flexDir="column"
              display="flex"
              variant="ghost"
              justifyContent="center"
              onClick={() => {
                setExpand(!expand);
              }}
            >
              <MinusIcon boxSize={4} color="#665AE7" />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    )}
  </>
}
