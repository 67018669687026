import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Equipament from './Pages/Equipament/Equipament'
import EquipamentButtonTV from './Pages/Equipament/EquipamentButtonTV'
import EquipamentButtonPOS from './Pages/Equipament/EquipamentButtonPOS'
import Financial from './Pages/Financial/Financial'
import Login from './Pages/Login/Login'
import Main from './Pages/Main/Main'
import MainConfig from './Pages/Main/MainConfig'
import MainConfigOption from './Pages/Main/MainConfigOption'
import Users from './Pages/Users/Users'
import UsersNew from './Pages/Users/UsersNew'
import UsersSelect from './Pages/Users/UsersSelect'
import Statistic from './Pages/Statistic/Statistic'
import { useAuth } from './Context/auth'
import { AlertProvider } from './Context/alert'

export default function App() {
  const { signed, loading } = useAuth();

  console.log({ logado: signed, loading });
  return (
    <BrowserRouter>
      <AlertProvider>
        {!signed
          ?
          <Switch>
            <Route exact path="/login" component={Login} />
            {!loading && <Redirect from="*" to="/login" />}
          </Switch>
          :
          <Switch>
            <Route exact path="/" component={Main} />
            <Route exact path="/MainConfig" component={MainConfig} />
            <Route exact path="/MainConfig/:server" component={MainConfigOption} />
            <Route exact path="/Statistic" component={Statistic} />
            <Route exact path="/EquipamentPOS/:macAddress" component={EquipamentButtonPOS} />
            <Route exact path="/EquipamentTV/:macAddress" component={EquipamentButtonTV} />
            <Route exact path="/Equipament" component={Equipament} />
            <Route exact path="/Financial" component={Financial} />
            <Route exact path="/Users" component={Users} />
            <Route exact path="/UsersSelect/:idUser" component={UsersSelect} />
            <Route exact path="/UsersNew/" component={UsersNew} />
            <Redirect from="*" to="/" />
          </Switch>
          }
        </AlertProvider>
      </BrowserRouter>
  
  )
}
