import { Flex, Text,
         Select,Image } from "@chakra-ui/react";
import { WrapperBG } from "../wrapperBG";
import { RouteComponentProps, useHistory } from "react-router-dom";
import api_url from "../../Services/Api";
import { useEffect, useState } from "react";
import ImageOrPlaceholder from "../../Components/ImageOrPlacehold";
const config = [
  { nome: "Porcentagem:" },
  { nome: "Mostrar:" },
  { nome: "Mostrar ao gerente:" },
];



function MainConfigOption({history, match}: RouteComponentProps<{server:string}>) {
  const [clientConst, setClient] = useState<IClient>();

  const [porcentagem,setPorcentagem] = useState("");
  const [viewGerente,setViewGerente] = useState("");


async function Login(porcentagem: string, viewGerente: string) {
  const response = await api_url.post('/configuracaoForm', {
    porcentagem,
    viewGerente,
  }).then(x => x.data);
}

  useEffect(() => {
    async function fetchServer(servidor: string) {
      const clientResponse = await api_url.get<{data:IClient}>(`/clients/${servidor}`).then(res => res.data.data);
      console.log(clientResponse);
      setClient(clientResponse);
    };

    fetchServer(match.params.server);
  }, []);

  return (
    <WrapperBG
      funcaoIcon={() => {
        history.goBack();
      }}
    >
      <Flex justifyContent="space-between">
        <Text
          textColor="#2654C5"
          fontSize="1.4rem"
          p={1}
          fontWeight="600"
          alignSelf="left"
        >
          {clientConst?.clientName}
        </Text>
        <ImageOrPlaceholder
          className="photo"
          src={"https://001."+ clientConst?.baseDomain + "/api/media/favicon/"}
        />
        {/* <Text
          textColor="#2654C5"
          fontSize="2xl"
          p={1}
          fontWeight="600"
          alignSelf="left"
          marginLeft="1rem"
        >
          Imagem
        </Text> */}
      </Flex>
      <Flex h="40rem">
        <form className="form-container">
          <Flex flexDir="column" w="100%" overflow="auto" marginTop="2rem">

         
            <Flex
              justifyContent="space-between"
              alignSelf="center"
              borderRadius="xl"
              mt="2"
              w="100%"
              p={2}
              color="black"
              textColor="#2654C5"
            >
              <Text fontSize="lg" textColor="#44496F" alignSelf="center">
                Porcentagem do resultado:
              </Text>
              <Flex>
                <Select
                  textColor="#2654C5"
                  size="sm"
                  borderRadius="2xl"
                  boxShadow="2xl"
                  onChange={(e)=>{
                    setPorcentagem(e.target.value);
                  }}
                >
                  <option value="10%">10%</option>
                  <option value="20%">20%</option>
                  <option value="30%">30%</option>
                  <option value="40%">40%</option>
                  <option value="50%">50%</option>

                </Select>
              </Flex>
            </Flex>

            <Flex
              justifyContent="space-between"
              alignSelf="center"
              borderRadius="xl"
              mt="2"
              w="100%"
              p={2}
              color="black"
              textColor="#2654C5"
            >
              <Text fontSize="lg" textColor="#44496F" alignSelf="center">
                Mostrar:
              </Text>
              <Flex>
                <Select 
                  textColor="#2654C5"
                  size="sm"
                  borderRadius="2xl"
                  boxShadow="2xl"
                  onChange={(e)=>{
                    setViewGerente(e.target.value);
                  }}
                >
                  <option value="Sim">Sim</option>
                  <option value="Nao">Não</option>
                
                </Select>
              </Flex>
            </Flex>

            <Flex
              justifyContent="space-between"
              alignSelf="center"
              borderRadius="xl"
              mt="10"
              w="90%"
              p={2}
              color="black"
              textColor="#2654C5"
            >
              <Text textColor='gray'>
              Essa parte ainda está em desenvolvimento

              </Text>
            </Flex>
          </Flex>
        </form>
      </Flex>
    </WrapperBG>
  );
}
export default MainConfigOption;
