import styled from "@emotion/styled";


//STYLE DO USER SELECT

export const WhiteBox = styled.div`
    /* className:"BoxBottom"; */
    flex-direction:column;
    justify-content:center;
    margin-bottom:0;
    align-self:center;
    justify-items:center;
    align-items: center;
    display: flex;
    padding-top: 2rem;
`;

export const FormSubmitFlex = styled.div`
    height:100vh;
    max-height:51vh;
    padding: 0 1.5rem;

    display: flex;
    flex-direction: column;

    overflow:auto;
    overflow-y:scroll;
    
    text-align: center;
`;

export const ButtonChangePass = styled.button<{bg:string}>`
    color:white;
    background-color:${props => props.bg};
    align-self:center;
    align-items:center;
    align-self:center;
    font-weight: bold;
    padding: 0.2rem 1rem;
    border-radius: 40px;
    font-size: 1.4rem;
    outline: none;
    transition: 0.5s;
`;

export const FlexServers = styled.div`

    justify-content:space-between;
    align-self:center;
    border-radius:15px;
    /* mt:2; */
    width:100%;
    padding:0.3rem;
    margin: 0.4rem;
    color:black;
    box-shadow: 0px 2px 2px 1px rgb(0, 0, 1, 0.1);
    display: flex;

`;

export const TextServers = styled.p`
    padding-left: 0.2rem;
    font-weight:bold;
    font-size:1.1rem;
    color:#44496F;
    align-self:center;
`;

