
import { useForm } from "react-hook-form";
import { InputFieldP } from "../../Components/InputFieldP";
import { WrapperBG } from "../wrapperBG";
import { Flex,Button } from "@chakra-ui/react";
import { RouteComponentProps } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";
import { BsEyeSlashFill } from "react-icons/bs";
import React from "react";
import { useAuth } from "../../Context/auth";
import {Container,Input,ContainerInput,
        LabelTitle,Content,ImageLogin,
        ContentInputsLogin,ContainerBtn,
        BtnLogin, Descripition,ContentPasswordBtn, FeedBackUser} from "./Style";
import fundoImage from "../../SVG/circulo_hub.png";
import Logo from "../../SVG/Logo_-_MR_Keno_-_Vertical_-_POT_-_2048_x_2048.png";
import { useState } from "react";



const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const { register, handleSubmit } = useForm();
  const { Login } = useAuth();
  const [feedbackUser, setfeedbackUser] = useState("")
  const [passwordVisible,setPasswordVisible] = useState(false);


  async function handleLogin(values: {userName: string, password: string,setToken:string}) {
      try {
        console.log("cliquei com sucesso");
        await Login(values.userName,values.password);  
      } catch (err) {
        console.log(err.response.data.message);
        setfeedbackUser(err.response.data.message);
      }
  }
 
  

 
  return (
    <Container  fundo={fundoImage}>
      <Content>
        <ImageLogin src={Logo}></ImageLogin>
      </Content>
      <ContentInputsLogin onSubmit={handleSubmit(handleLogin)}>
        
        <ContainerInput>
          <Input type="text" id="userName"
           placeholder="Nome de usuário" {...register("userName")}/>
        </ContainerInput>
        <ContainerInput>
            <Input type={passwordVisible? "text":"password"} id="password"
             placeholder="Senha" {...register("password")}/>
            <ContentPasswordBtn 
              type="button"
              display={passwordVisible? "flex":"none"}
              onClick={() => {
               
                setPasswordVisible(!passwordVisible)
              }}>
              <BsEyeSlashFill />
            </ContentPasswordBtn>
            <ContentPasswordBtn 
              type="button"
              display={passwordVisible? "none":"flex"}
              onClick={() => {
                setPasswordVisible(!passwordVisible)
              }}>
              <IoEyeSharp />
            </ContentPasswordBtn>
            <ContentPasswordBtn 
              type="button"
              display={passwordVisible? "none":"flex"}
              onClick={() => {
                setPasswordVisible(!passwordVisible)
              }}>
              <IoEyeSharp />
            </ContentPasswordBtn>
            <FeedBackUser>{feedbackUser}</FeedBackUser>
        </ContainerInput>
       
        <ContainerBtn>
          <BtnLogin>Login</BtnLogin>
        </ContainerBtn>
      </ContentInputsLogin>
      <Descripition>Bem Vindo ao Hub!!</Descripition>
    </Container>
  );
}

export default Login;