import { Button, CheckboxGroup, Divider, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Wrapper } from "../wrapper";
import { Switch } from "@chakra-ui/react";
import { RouteComponentProps } from "react-router-dom";
import { InputFieldP } from "../../Components/InputFieldP";
import { ContentPasswordBtn, FeedBackUserMensage } from "./Style";
import { IoEyeSharp } from "react-icons/io5";
import { useForm   } from "react-hook-form";
import { useAuth } from "../../Context/auth";
import { BsEyeSlashFill } from "react-icons/bs";
import api_url from "../../Services/Api";
import { ButtonChangePass, FlexServers, FormSubmitFlex, TextServers, WhiteBox } from "./StyleUsersSelect";

type ServerResponse = {
  data: IServers[];
};
type UserResponse = {
  data: UserDate;
};

type UserDate = Omit<ISubordinates,"servers">&{servers:string[]};

function UserSelect({history, match}: RouteComponentProps<{idUser:string}>) {

  const [servers, setServer] = useState<IServers[]>([]);
  const [serverSubordinate, setServerSubordinate] = useState<string[]>([]);
  const [userSubordinate, setUserSubordinate] = useState<UserDate>();
  const [passwordConfirmVisible,setPasswordConfirmVisible] = useState(false);
  const [passwordVisible,setPasswordVisible] = useState(false);
  const [expandChangePass, setExpandChangePass] = useState(false);
  const [feedbackUser, setfeedbackUser] = useState("");
  const { register, handleSubmit,reset } = useForm();
  const { ChangePassword,ChangeServer } = useAuth();
  const [valueAlterado, setValueAlterado] = useState(false);
  // const notify = () => toast(feedbackUser);
  //console.log(match.params.idUser);

  const [SelectedServers, setSelectedServers] = useState<string[]>([]);

  async function fetchServer() {
    const response = await api_url.get<ServerResponse>(
      "/users/me/servers"
    );
    setServer(response.data.data);
  };

  async function fetchSubordinate(idUser:string) {
    const response = await api_url.get<UserResponse>(
      "/users/"+idUser
    );
    console.log("valor:");
    console.log(response.data.data.servers);
    setServerSubordinate(response.data.data.servers);
    setUserSubordinate(response.data.data);

    setSelectedServers(response.data.data.servers);
  };

  async function handleChangePassword(values: {password: string, passwordConfirmation: string}) {
    try {

      await ChangePassword(values.password,values.passwordConfirmation,userSubordinate?.id);  
      setfeedbackUser("Senha alterada com sucesso");
    } catch (err) {
      console.log(err.response.data.message);
      setfeedbackUser(err.response.data.message);
    }
  }

  async function hangleChangeServer(values: {servers:string[]}) {

    //console.log(match.params.idUser);
    try {
      await ChangeServer(SelectedServers,match.params.idUser);  
      setfeedbackUser("Servidores alterados com sucesso");
    } catch (err) {
      //console.log(err.response.data.message);
      setfeedbackUser(err.response.data.message);
    }
  }
  
  useEffect(() => {
   
  }, [valueAlterado])

  useEffect(() => {
    fetchServer();
    fetchSubordinate(match.params.idUser);
    
  }, []);

  return (
    
    // fundo de tudo
    <Wrapper
      name={"Alterando valores"}
      conf={false}
      BoxContent={
        <>
          {/* Topo da box branca */}
          <Flex
            className="BoxTopo"
            flexDir="column"
            justifyContent="space-between"
          >
            <Flex flexDir="row">
              <Text fontWeight="bold" fontSize="xl" textColor="#665AE7">
                {userSubordinate?.userName}
              </Text>
            </Flex>
            <Flex>
              <Text fontWeight="bold" fontSize="xl" textColor="#BDB7F1">
                Gerente
              </Text>
            </Flex>
          </Flex>

          {/* bottom da box branca */}
          <WhiteBox>
            {!expandChangePass && 
            <Flex marginBottom="1rem">
              <ButtonChangePass
                bg="#665AE7"
                onClick={() => {
                  setExpandChangePass(!expandChangePass);
                }}
              >
                mudar senha
              </ButtonChangePass>
            </Flex>}
          </WhiteBox>
          
          <Flex  flexDir="column" maxHeight="70vh" alignSelf='center'>
            {expandChangePass && (
              <form onSubmit={handleSubmit(handleChangePassword)}>
                <Flex flexDir='column' height="70vh"  alignSelf='center' gridGap='1rem' >
                  
                  <Text  fontSize='2xl' alignSelf='center' paddingTop='4rem' >
                    Redefinir senha
                  </Text>
                  
                  <Divider 
                    border="2px"
                    orientation="horizontal"
                    bgColor="#5247d0" 
                    borderColor="#5247d0"
                    margin="1rem 0rem"
                  />
                  <InputFieldP
                    id="password"
                    type={passwordVisible? "text":"password"}
                    placeholder="Senha"
                    borderRadius='full'
                    {...register("password")}
                  />
                  <InputFieldP
                    id="passwordConfirm"
                    type={passwordConfirmVisible? "text":"password"}
                    placeholder="Confirmar senha"
                    borderRadius='full'
                    {...register("passwordConfirmation")}
                  />

                      <ContentPasswordBtn 
                          type="button"

                          display={passwordVisible? "flex":"none"}
                          onClick={() => {
                            setPasswordVisible(!passwordVisible)
                            setPasswordConfirmVisible(!passwordConfirmVisible)
                          }}>
                              <BsEyeSlashFill />
                      </ContentPasswordBtn>
                      <ContentPasswordBtn 
                        type="button"
                        display={passwordVisible? "none":"flex"}
                        onClick={() => {
                          setPasswordVisible(!passwordVisible)
                          setPasswordConfirmVisible(!passwordConfirmVisible)
                        }}>
                      <IoEyeSharp />
                      </ContentPasswordBtn>
                  <Flex  textAlign='center' alignSelf='center' flexDir='column' >

                    <FeedBackUserMensage>
                      {feedbackUser}
                    </FeedBackUserMensage>

                    <ButtonChangePass bg="#665AE7">
                      mudar senha
                    </ButtonChangePass>

                    <Flex alignSelf='center'>
                      <Button
                        display="flex"
                        variant="ghost"
                        textColor='#BDB7F1'
                        fontSize='lg'
                        onClick={() => {
                          setExpandChangePass(!expandChangePass);
                        }}
                      >
                        cancelar
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </form>
            )}
          </Flex>
        </>
      }
    >
      {/* //box branca */}
      <form onSubmit={handleSubmit(hangleChangeServer)}>
      <FormSubmitFlex>   
          {servers.map((server,indice) => {
            return (
          
              <FlexServers key={indice}>
                <TextServers>
                  {server?.subDomain} - {server?.clientName}
                </TextServers>
              
                <Switch size="md" id={server?.id} isChecked={SelectedServers.includes(server.id)} value={server.id} colorScheme="purple" borderColor='#665AE7' outlineColor='#665AE7'  
                onChange={() => {
                  if (SelectedServers.includes(server.id)){
                      setSelectedServers(SelectedServers.filter((x)=> x !== server.id))
                  }else{
                    setSelectedServers([...SelectedServers,server.id])
                  }
                  setValueAlterado(true);
                }}/>

              </FlexServers>
 
            );
           })}
          </FormSubmitFlex>
          <Flex justifyContent='center' alignItems='center' justifyItems='flex-end'  marginTop='2rem'>
              <ButtonChangePass
              bg={valueAlterado == true ?
                "#665AE7"
              :
                "#9d9d9d "
              }
              type = "submit"
              disabled={!valueAlterado ? true:false}
            >
              Salvar Alterações 
            </ButtonChangePass>
          </Flex>
        
        </form>
        
    </Wrapper>
  );
}
export default UserSelect;
