import { Button, Divider, Flex, Input, Text } from "@chakra-ui/react";
import { WrapperBG } from "../wrapperBG";
// import { BorderShadow } from 'react-native-shadow'
import { BsEyeSlashFill, BsGearFill } from "react-icons/bs";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../Context/auth";
import { userInfo } from "os";
import { useForm } from "react-hook-form";
import { InputFieldP } from "../../Components/InputFieldP";
import { ContentPasswordBtn, FeedBackUserMensage } from "./Style";
import { IoEyeSharp } from "react-icons/io5";
import { Descripition } from "../Login/Style";

const clientes = [
  { nome: "SuperSorte" },
  { nome: "Bola90" },
  { nome: "BinGold" },
];
type InputProps = {
  placeHolder: string;
};



// function componentDidMount(name:string, password:string, passwordConfirmation:string, managerId:string) {
//   const data = { name, password, passwordConfirmation, managerId };
//   axios.post("https://hub.mrkeno.app/api/v1/register", data);
// }

const InputField: React.FC<InputProps> = ({ children, placeHolder }) => (
  <Input
    isInvalid
    errorBorderColor="#665AE7"
    textColor="#665AE7"
    colorScheme="#665AE7"
    borderColor="#665AE7"
    focusBorderColor="#665AE7"
    borderWidth="bold"
    placeholder={placeHolder}
    borderRadius="full"
    bg="white"
    h="3rem"
    fontSize="lg"
  >
    {children}
  </Input>
);

function App() {
  const { user } = useAuth();
  const { CreateUser } = useAuth();
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const [passwordVisible,setPasswordVisible] = useState(false);
  const [passwordConfirmVisible,setPasswordConfirmVisible] = useState(false);
  const [feedbackUser, setfeedbackUser] = useState("");


  async function handleCreateUser(values: {userName: string, password: string, passwordConfirmation: string, managerId:string}) {
    try {
      await CreateUser(values.userName,values.password,values.passwordConfirmation, values.managerId);  
      setfeedbackUser("Usuário criado com sucesso");
    } catch (err) {
      console.log(err.response.data[0].message)
      setfeedbackUser(err.response.data[0].message);
    }
    //axios.post("https://hub.mrkeno.app/api/v1/register", values);
  }
  return (
    // fundo de tudo mesmo
    
    <WrapperBG
      funcaoIcon={() => {
        history.goBack();
      }}
    >
          <Flex h="45rem" justifyContent="flex-start" flexDir="column" >
            <Text
              textColor="#2654C5"
              fontSize="2xl"
              p={1}
              fontWeight="600"
              alignSelf="center"
            >
              Novo usuario
            </Text>
          <Flex zIndex={3} justifyContent="center">
            <Divider
              border="3px"
              orientation="horizontal"
              bgColor="#665AE7"
              borderColor="#665AE7"
              w="80%"
            />
          </Flex>
        
          <form  onSubmit={handleSubmit(handleCreateUser)}>
            <Flex flexDir="column" p={5} paddingTop="6rem" h='40rem' >

              <InputFieldP placeholder="Nome"  {...register("userName")}/>

              <Flex marginTop="4rem" flexDir="column">
                <InputFieldP placeholder="Senha"   type={passwordConfirmVisible? "text":"password"} {...register("password")}/>
              </Flex>

              <Flex marginTop="1rem" flexDir="column">
                <InputFieldP  placeholder="Confirmar Senha"  type={passwordConfirmVisible? "text":"password"} {...register("passwordConfirmation")}/>
              </Flex>
              <ContentPasswordBtn 
                type="button"
                display={passwordVisible? "flex":"none"}
                onClick={() => {
                  setPasswordVisible(!passwordVisible)
                  setPasswordConfirmVisible(!passwordConfirmVisible)
                }}>
                    <BsEyeSlashFill />
            </ContentPasswordBtn>
            <ContentPasswordBtn 
              type="button"
              display={passwordVisible? "none":"flex"}
              onClick={() => {
                setPasswordVisible(!passwordVisible)
                setPasswordConfirmVisible(!passwordConfirmVisible)
              }}>
            <IoEyeSharp />
            </ContentPasswordBtn>

              <Input type='hidden' value={user?.id} {...register("managerId")}></Input>
              <FeedBackUserMensage>
                {feedbackUser}
              </FeedBackUserMensage>

              <Flex padding="1rem" flexDirection='column'>
                <Button
                  borderRadius="full"
                  bgColor="#665AE7"
                  textColor="white"
                  fontSize="2xl"
                  onClick={() => console.log("teste")}
                  type="submit"
                >
                  Cadastrar
                </Button>

                <Button bgColor="transparent" textColor="#665AE7">
                  <Link to="/Users">Cancelar</Link>
                </Button>
              </Flex>
             
              
            </Flex>
          </form>
        
        <Flex></Flex>
      </Flex>
    </WrapperBG>
  );
}
export default App;
