import styled from "@emotion/styled";

export const Container = styled.div<{fundo: string}>`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100vw;
    height: 100vh;
    background: url(${props => props.fundo});
    background-repeat: no-repeat;
    background-position: top center;
    background-position-y: -80px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;


export const ContentInputsLogin = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    padding-top: 4rem;
`;

export const ImageLogin = styled.img`
    width: 60%;
    padding: 6rem 0 1rem;
`;

export const ContainerInput = styled.label`
    display: flex;
    flex-direction: column;
    width: 78%;
    margin-top: 2rem;
`;

export const LabelTitle = styled.h2`
    display: flex;
    flex-direction: column;
`;

export const Input = styled.input`
    height: 2.8rem;
    padding: 1rem;
    border: 1px solid #665AE7;
    background-color: #f2f2f2;
    color: #212121;
    font-weight: bold;
    outline: none;
    transition: 0.6s;
    :focus{
        box-shadow: 0px 0px 8px 0px rgb(0, 0, 0, 0.25) !important;
    }
`;

export const ContentPasswordBtn = styled.button<{display:string}>`
    // position: absolute;

    display: ${props=>props.display};
    justify-content: center;
    align-self: flex-end;
    flex-direction: column;

    height: 2.8rem;
    font-size: 1.4rem;
    margin-right: 0.6rem;

    color: #665ae7a2;
    outline: none;
`;

export const ContainerBtn = styled.div`
    padding-top: 3rem;
    width: 78%;
`;

export const BtnLogin = styled.button`
    padding: 0.7rem 1rem;
    width: 100%;
    background: #665AE7;
    border-radius: 10px;

    color: #FFFFFF;
    font-weight: bold;

    outline: none;

    transition: 0.5s;

    :hover{
        background: #665AE7;
        opacity: 0.9;
    }
`;

export const Descripition = styled.p`
    padding-top: 4rem;

    font-size: 1.1rem;
    color: #665AE7;
    font-weight: bold;
`;

export const FeedBackUserMensage = styled.p`
    font-size: 1.1rem;
    color: #990000;
    font-weight: bold;
    position: relative;
    min-height: 10rem;
    max-height: 10rem;
    height: 10rem;
`;

