import { Link } from "react-router-dom";
import {
  Button,
  Flex,
  Text,
  SkeletonText,
  Spinner,
  Stack,
  useMenu,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { IoTvOutline, IoPeopleCircle } from "react-icons/io5";
import { BsGraphUp } from "react-icons/bs";
import { MdAttachMoney } from "react-icons/md";
import { Wrapper } from "../wrapper";
import { api_url } from "../../Services/Api";
import { useAuth } from "../../Context/auth";
import MainBox, { PartialResponse } from "./MainBox";
import NotificationBox from "./NotificationBox";
import { Refresh } from "../../Components/Refresh";
import { useAlert } from "../../Context/alert";
import ImageOrPlaceholder from "../../Components/ImageOrPlacehold";
import { Server } from "http";
import { CicleOnline, ContainerList, ContentNotificationNumber } from "./Style";
import { MinusIcon, SmallAddIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";
import { AiFillCloseCircle } from "react-icons/ai"
import NotificationList from "./NotificationList";
import { BiRefresh } from "react-icons/bi";

type ButtonProps = {
  icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  to: string;
};

const MenuButton: React.FC<ButtonProps> = ({ children, icon, to }) => (
  <Button
    as={Link}
    to={to}
    mt={1}
    leftIcon={icon}
    iconSpacing="0"
    bg="#F0EFEF"
    boxShadow="lg"
    paddingBlock="2rem"
    fontSize="2rem"
    className="shadow-btn"
  >
    {children}
  </Button>
);

type ServerlResponse = {
  data: IServers[];
};
type NotificationslResponse = {
  message: string;
  title: string;
  data: INotification[];
};

function Main() {


  //animations
  const constraintsRef = useRef(null);


  const { user, Refresh: userRefresh } = useAuth();
  const [servers, setServers] = useState<IServers[]>();
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [partials, setPartials] = useState<PartialResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [expandNotification, setExpandNotification] = useState(false);

  const firstNotification = useMemo(() => {
    return notifications[0];
  },
    [notifications]
  );




  function clearNotifications(){
    handleRemoveNotifications(firstNotification);
    setNotifications([]);
    setExpandNotification(false);
    localStorage.setItem('KHB@NTF', "");

  }

  const IsServerOnline = (id: string) => {
    const server = partials?.data.find((x) => x.serverId === id);
    if (server === undefined) {
      return "#aaaaaa";
    }
    if (server.online === true) {
      return "#5BE569";
    }
    return "#990000";
  };

  async function fetchTotal() {
    const response = await api_url.get<PartialResponse>(
      "/reports/daily_partials"
    );
    setPartials(response.data);
  }

  async function fetchServers() {
    try {
      const response = await api_url.get<ServerlResponse>("users/me/servers");
      setServers(response.data.data);
    } catch {
      setServers([]);
    }
  }
  // api/v1/notifications/?last=ckqwu5s8p0001ilu92dok39l9

  //enviar ultima notificação para servidor:
  async function fetchNotifications() {
    const oldNotifications = JSON.parse(localStorage.getItem('KHB@NTF') || '[]') as INotification[];
    const newNotifications = await api_url.get<NotificationslResponse>(
      "/notifications",
      {
        params: {
          last: user?.lastNotification
        }
      }
    ).then(resonse => resonse.data.data).catch(x => []);


    handleSetNotifications([...oldNotifications, ...newNotifications].filter((ntf, index, arr) => {
      return arr.findIndex(n => n.id === ntf.id) === index;
    }));
  }

  async function fetchData() {
    fetchTotal();
    await Promise.all([fetchServers().catch(), fetchNotifications().catch()]);
    setIsLoading(false);
  }


  function handleSetNotifications(notifications: INotification[]) {
    localStorage.setItem('KHB@NTF', JSON.stringify(notifications));
    setNotifications(notifications);
  }

  function handleRemoveNotifications(notification: INotification) {
    const newArray = notifications.filter(ntf => ntf.id !== notification.id);
    localStorage.setItem('KHB@NTF', JSON.stringify(newArray));
    setNotifications(newArray);
  }


  useEffect(() => {
    userRefresh().then(() => fetchData());
    
    document.addEventListener("Notificacao", fetchData);
    return () => {
      document.removeEventListener("Notificacao", fetchData)
    };
  }, []);


  return (
    <Flex >
      <Wrapper
        back={false}
        name={`Olá, ${user?.userName}`}
        BoxContent={<MainBox partials={partials} />}
        blur={expandNotification ? "blur(10px)" : "blur(0px)"}
      >
        <Flex
          h="100%"
          flexDir="column"
          justifyContent="space-evenly"
          userSelect="none"
        >
          {/* //buttons */}
          <Flex
            padding="1.7rem 1.5rem 1.3rem 1.5rem"
            flexDir="row"
            height="7.8rem"
            justifyContent="center"
            gridColumnGap="5"
          >
            <MenuButton to="/Equipament" icon={<IoTvOutline />} />
            <MenuButton to="/Users" icon={<IoPeopleCircle />} />
            <MenuButton to="/Financial" icon={<MdAttachMoney />} />
            <MenuButton to="/Statistic" icon={<BsGraphUp />} />
          </Flex>
          {/* Servidores */}
          <Text
            fontWeight="bold"
            fontSize="2xl"
            textColor="#44496F"
            paddingLeft="1.8rem"
            mb="0.7rem"
          >
            Servidores
          </Text>
          <Flex
            padding="0 1rem 2.8rem 2rem"
            flexDir="row"
            overflowX="scroll"
            minHeight="13rem"
            className="scroll-transparent"
          >
            {servers?.map((server) => (
              <Flex key={server.id} pr="1rem" >
                <Button
                  className="shadow-server"
                  flexDir="column"
                  borderRadius="xl"
                  padding="0.3rem 0.5rem"
                  boxShadow="md"
                  bg="#F0EFEF"
                  height="10rem"
                  minW="6rem"
                  maxW="6rem"
                  flex={1}
                  userSelect="none"
                  justifyContent="center"
                  position="relative"
                  // window.open("https://www.google.com", "_blank");
                  // window.location.href="https://www.google.com"
                  // window.open("https://"+ server.subDomain + server.clientName+".app.com", "_blank")
                  // console.log("teste:" + server.clientName.replace(" ","")
                  onClick={() => window.open("https://"+ server.subDomain + "." +server.clientName.replace(" ","").replace(".","")+".app", "_blank")}
                >
                  <Flex
                    flexDir="row"
                    position="absolute"
                    top="0.4rem"
                    left="0.4rem"
                    alignItems="center"
                  >
                    <CicleOnline bg={IsServerOnline(server.id)} />
                  </Flex>

                  <Flex justifyContent="center">
                    {!isLoading ? (
                      <ImageOrPlaceholder
                        className="photo"
                        src={server.url + "/api/media/favicon/"}
                      />
                    ) : (
                      <Flex
                        padding="0 1rem 0 0.7rem"
                        align="center"
                        alignSelf="center"
                        paddingBottom="1.3rem"
                      >
                        <Spinner thickness="4px" color="blue.500" />
                      </Flex>
                    )}
                  </Flex>
                  <SkeletonText isLoaded={!isLoading}>
                    <Text
                      fontWeight="bold"
                      fontSize="2xl"
                      textColor="#717591"
                      align="center"
                      color="black"
                    >
                      {server.subDomain}
                    </Text>
                    <Text
                      fontWeight="bold"
                      fontSize="sm"
                      textColor="black"
                      alignSelf="center"
                      textAlign="center"
                    >
                      {server.clientName}
                    </Text>
                  </SkeletonText>
                </Button>
              </Flex>
            ))}
          </Flex>
          <Flex justifyContent='space-between'>
            <Text
              fontWeight="bold"
              fontSize="2xl"
              textColor="#44496F"
              padding="0rem 1.2rem 0 1.8rem"
            >
              Ultimas atividades
            </Text>
            <Flex paddingRight='1.8rem' >    
              {/* <Refresh onClick={fetchNotifications} /> */}
              <Link to={"/Main"}>
               <Flex paddingRight='0.5rem'> 
                  <Refresh onClick={fetchNotifications} />
               </Flex>
                
              </Link>
              
              {/* //contador de notificações */}
              <ContentNotificationNumber>{notifications.length}</ContentNotificationNumber>
            </Flex>
          </Flex>
          <Flex width="100%" height="8rem" padding="0.5rem 1.8rem 0.5rem 1.8rem"
            onClick={() => {
              setExpandNotification(true)
            }}
            flexDir="column"
            justifyContent="center"
          >
            <motion.div ref={constraintsRef}>
              {!expandNotification && (
                <motion.div
                  dragConstraints={constraintsRef}
                  drag={firstNotification ? "x" : false}
                  whileDrag={{ scale: 1.05 }}
                  onDragEnd={(event, info) => {
                    if (Math.abs(info.offset.x) > 200) {
                      handleRemoveNotifications(firstNotification);
                    }
                  }}
                >
                  {/* box de notificação */}
                  <Flex position="relative" >
                    <NotificationBox loading={isLoading} notification={firstNotification} largura="100%" />
                  </Flex>
                </motion.div>
              )}
            </motion.div>
          </Flex>
        </Flex>


      </Wrapper>
      {/* lista de notificações */}
        <NotificationList
          show={expandNotification}
          notifications={notifications}
          onClose={() => setExpandNotification(false)}
          onRemove={handleRemoveNotifications}
          clearNotifications={clearNotifications}
        />
    </Flex>
  );
}

export default Main;
