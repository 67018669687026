import * as React from "react";
import { motion } from "framer-motion";
import { BiFontSize } from "react-icons/bi";

const button = {
  rest: { scale: 1 },
  hover: { scale: 1.1 },
  pressed: { scale: 0.95 }
  
};

interface props{
    onClick: ()=> void
}

export const Refresh:React.FC<props> = ({ onClick }) => {

  return (
    <motion.button

      className="refresh"
      variants={button}
      initial="rest"
      whileHover="hover"
      whileTap="pressed"
    >
      <motion.svg
        width="30"
        height="30"
        
        
        xmlns="http://www.w3.org/2000/svg"
        variants={{ 

          rest: { rotate: 360 },
          hover: { rotate: 360, transition: { duration: 0.4 } }
        }}
        animate={{
            scale: [1, 1.3, 1.3, 1, 1],
   
            rotate: [0, 0, 270, 270, 0],
            borderRadius: ["20%", "20%", "50%", "50%", "20%"],
          }}
      >
        
          <path
          
            d="M 10 11 H 7.101 c 0 -0.003 0 -0.006 0.001 -0.009 c 0.065 -0.319 0.163 -0.634 0.291 -0.937 c 0.126 -0.297 0.281 -0.583 0.461 -0.85 c 0.178 -0.264 0.384 -0.513 0.61 -0.74 C 8.691 8.238 8.94 8.032 9.206 7.853 c 0.266 -0.18 0.551 -0.334 0.848 -0.46 c 0.302 -0.128 0.617 -0.226 0.938 -0.291 c 0.658 -0.135 1.357 -0.135 2.018 0 c 0.318 0.065 0.634 0.163 0.937 0.291 c 0.296 0.125 0.581 0.281 0.85 0.461 c 0.266 0.179 0.514 0.384 0.738 0.609 l 1.416 -1.412 c -0.314 -0.316 -0.664 -0.604 -1.036 -0.855 c -0.373 -0.252 -0.773 -0.47 -1.188 -0.646 c -0.425 -0.18 -0.868 -0.317 -1.315 -0.408 c -0.923 -0.189 -1.899 -0.189 -2.819 0 c -0.449 0.092 -0.892 0.229 -1.316 0.409 C 8.858 5.727 8.458 5.944 8.086 6.196 C 7.716 6.445 7.368 6.733 7.05 7.05 S 6.445 7.716 6.197 8.085 c -0.252 0.373 -0.47 0.773 -0.646 1.19 c -0.18 0.424 -0.317 0.867 -0.408 1.315 C 5.115 10.725 5.1 10.863 5.08 11 H 2 l 4 4 L 10 11 Z M 14 13 h 2.899 c -0.001 0.003 0 0.006 -0.001 0.008 c -0.066 0.324 -0.164 0.639 -0.292 0.938 c -0.123 0.293 -0.278 0.579 -0.459 0.848 c -0.179 0.264 -0.385 0.514 -0.613 0.742 c -0.225 0.225 -0.473 0.43 -0.739 0.61 c -0.268 0.18 -0.553 0.335 -0.849 0.461 c -0.303 0.128 -0.618 0.226 -0.938 0.291 c -0.657 0.135 -1.357 0.135 -2.017 0 c -0.319 -0.065 -0.634 -0.163 -0.937 -0.291 c -0.297 -0.126 -0.583 -0.281 -0.85 -0.461 c -0.264 -0.178 -0.513 -0.384 -0.74 -0.61 L 7.05 16.95 c 0.317 0.317 0.666 0.605 1.035 0.854 c 0.373 0.252 0.773 0.47 1.19 0.646 c 0.424 0.18 0.867 0.317 1.315 0.408 C 11.051 18.952 11.525 19 12 19 s 0.949 -0.048 1.408 -0.142 c 0.449 -0.091 0.893 -0.229 1.317 -0.409 c 0.415 -0.176 0.815 -0.393 1.188 -0.645 c 0.372 -0.251 0.722 -0.54 1.035 -0.854 c 0.317 -0.317 0.605 -0.666 0.855 -1.037 c 0.254 -0.377 0.472 -0.777 0.645 -1.187 c 0.178 -0.42 0.315 -0.863 0.408 -1.316 c 0.027 -0.135 0.043 -0.273 0.063 -0.41 H 22 l -4 -4 L 14 13 Z"
            fill="#665AE7"
            fillRule="nonzero"
            transform='scale(1.46)'
            
            
          />
        
      </motion.svg>
    </motion.button>
  );
};