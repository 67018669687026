import { Flex, Button } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useRef } from 'react';
import { AiFillCloseCircle, AiOutlineClear } from 'react-icons/ai';
import { IoTrashBin } from 'react-icons/io5';
import NotificationBox from './NotificationBox';
import { ContainerList } from './Style';

type Props = {
  notifications: INotification[];
  onRemove: (ntf: INotification) => void;
  onClose: () => void;
  clearNotifications: () => void;
  show?: boolean;
}
const NotificationList: React.FC<Props> = ({ notifications, onRemove, onClose,clearNotifications, show }) => {
  const constraintsRef = useRef(null);

  if (!show) return (
    <>
    </>
  )

  return (
    <Flex className='topo 'width="100%" zIndex="3" height="100%" overflow="scroll" position="absolute"
      top="0" padding="5rem 0rem 0rem 0rem"
      onClick={() => {
      }}
      flexDir="column"
    >
      <ContainerList>
        <motion.div className="foi" animate={{ y: 0 }}
          transition={{ ease: "linear", duration: 0.35 }}
          initial={{ y: "100%" }}

          ref={constraintsRef}>
          {notifications.map((notification) => (
            <motion.div
              
              key={`frstNtf${notification.id}`}
              drag="x"
              layout
              transition={{
                type: "spring",
                stiffness: 600,
                damping: 30
              }}
              whileTap={{ cursor: "grabbing" }}
              dragConstraints={constraintsRef}
              whileDrag={{ scale: 1.05 }}
              style={{ marginBottom: "1rem" }}
              onDragEnd={(event, info) => {
                if (Math.abs(info.offset.x) > 200) {
                  onRemove(notification);
                }
                if ((notifications || []).length === 0) {
                  onClose();
                }


              }}
            >
              <NotificationBox  key={notification.id} loading={false} notification={notification} largura="100%"/>
            </motion.div>
          ))}
        </motion.div>
      </ContainerList>
      <Flex justifyContent="center" backgroundColor="transparent" position='absolute' bottom='3rem' width='100%' >
        <Button height="1rem" padding="0.5rem " backgroundColor="transparent" onClick={onClose}>
          <AiFillCloseCircle size={60} color="#665AE7" />
        </Button>
      </Flex>
      <Flex justifyContent="center" backgroundColor="transparent" position='absolute' bottom='3rem' paddingLeft='15rem' width='100%' >
        <Button height="0rem" colorScheme="whiteAlpha" padding="0.5rem" backgroundColor="transparent" onClick={clearNotifications} outline='none'>
          <IoTrashBin size={35} color="#665AE7" />
        </Button>
      </Flex>
    </Flex>
  );
}

export default NotificationList;