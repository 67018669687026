import {
  format,
  subDays,
  getDay,
  startOfMonth,
  differenceInMonths,
  differenceInDays,
} from "date-fns";
import {
  Button,
  ButtonProps,
  Flex,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsArrowReturnLeft } from "react-icons/bs";
import { GiSwapBag } from "react-icons/gi";
import { BiTrophy } from "react-icons/bi";
import { MdAttachMoney } from "react-icons/md";
import Calendar from "react-calendar";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { FiFilter } from "react-icons/fi";
import { Link } from "react-router-dom";
import { CalendarIcon } from "@chakra-ui/icons";
import api_url from "../../Services/Api";
import {
  ButtonPerfomance,
  BoxPerfomanceText,
  ButtonResume, ButtonServer,
  ButtonServerAll, FlexFilter, FlexResumeAndServer,
  GenericValueList, ServerListBox, ServerListBoxAll,
  TextResumeTitle,
  TextValueServerFinancial,
  TextTodos,
  ButtonTopPage,
  ButtonBackPage
} from "./Style";
import { MenuButton } from "../../Components/HorizontalButtons";
import { MenuButtonText } from "../../Components/MenuButtonText";
import { parse } from "date-fns";



type Stats = {
  soma_dias: number,
  media_aderencia: number,
  media_efetividade: number,
  inicio_atividade: string,
  fim_atividade: string,
  
}

type NumericStats = Pick<Stats, 'media_aderencia' | 'media_efetividade' | 'soma_dias'>;
type DailyStats = NumericStats & { data: string };

type ServerlTotal = Stats & {
  server: IServer;
  dias: DailyStats[]
};
type ApiResponse = {
  data: ServerlTotal[];
  total: Stats;
  server_time: string;
};



//gráfico
const hoje = new Date();
const ultimoDomingo = subDays(hoje, getDay(hoje));
const ultimoMes = new Date(startOfMonth(hoje));

const colors = [
  "#4169E1",
  "#5F9EA0",
  "#66CDAA",
  "#20B2AA",
  "#9370DB",
  "#7B68EE",
  "#00BFFF",
]

const initialFilter = {
  media_efetividade: true,
  media_aderencia: false
}

function convertDate(convertDate: string){

  return parse(convertDate.split(" ")[0].split("T")[0],"yyyy-MM-dd",new Date);
  // 2021-08-02T17:12:43.886Z

}



function App() {



  const [startDate, setStartDate] = useState(ultimoDomingo);
  const [endDate, setEndDate] = useState(new Date());

  const [expandFilter, setExpandFilter] = useState(false);

  const [expandResume, setExpandResume] = useState(false);
  const [selectedResumo, setSelectedResumo] = useState(false);

  const [expandServer, setExpandServer] = useState(true);
  const [selectedServidores, setSelectedServidores] = useState(true);

  const [mode, setMode] = useState<'D' | 'S' | 'M' | 'C'>('S');

  const [calendarInput, setCalendarInput] = useState(false);
  const [value, setValue] = useState(new Date());

  //Financeiro
  const [servers, setServers] = useState<ServerlTotal[]>([]);
  const [selectedServer, setSelectedServer] = useState<string>();
  const [daySum, setDaySum] = useState<DailyStats[]>([]);
  const [totalData, setTotalData] = useState<Stats>({} as Stats);

  // FILTRO
  const [clientes, setClientes] = useState<IClient[]>([]);
  const [selectedClients, setSelectedClients] = useState<string[]>([])

  //const valores para habilitar/desabilitar gráfico
  const [dataStartCalendar, setDataStartCalendar] = useState(new Date());
  const [dataEndCalendar, setDataEndCalendar] = useState(new Date());
  const [dataDifferenceCalendar, setDataDifferenceCalendar] = useState(0);



  //Buttons perfomance

  const [graphFilter, setGraphFilter] = useState(initialFilter);


  //Coletando dados
  type fetchProps = {
    startDate?: string;
    endDate?: string;
    selectedServer?: string;
    selectedClients?: string[];
  };

  async function fetchTotalGraphics({ startDate, endDate, selectedServer, selectedClients }: fetchProps = {}) {

    const { data, total } = await api_url.get<ApiResponse>(
      `/reports/stats${!selectedServer ? "" : `/${selectedServer}`}`, {
      params: {
        startTime: startDate,
        endTime: endDate,
        daily: true,
        client: selectedClients,
      }
    }).then(x => x.data).catch(x => ({} as ApiResponse));

    if (!data || !total) return;

    const serversFormatados = data.map(serverData => (
      {
        ...serverData,
        dias: serverData.dias.map(dia => ({ ...dia, data: dia.data.split("-")[2] }))
      }
    ));



    const valorInicial: DailyStats = {
      data: '--',
      media_aderencia: 0,
      media_efetividade: 0,
      soma_dias: 0,

      
    };
    //soma dos valores com base dos dias selecionados
    // console.log("data antes da soma");

    const somaDias = serversFormatados?.reduce((acc, server) => {
      server.dias?.forEach((dia, index) => {

        if (!acc[index]) acc[index] = { ...valorInicial, data: dia.data };
        const dict = acc[index];

        dict.media_aderencia += (dia.media_aderencia * 100);
        dict.media_efetividade += (dia.media_efetividade * 100);
        dict.soma_dias += Number(dia.data);

      })

      return acc;
    }, [] as DailyStats[]);

    somaDias.forEach(dia => {
      dia.media_aderencia = (dia.media_aderencia / serversFormatados.length)
      dia.media_efetividade = (dia.media_efetividade / serversFormatados.length)
    });

    total.media_aderencia *= 100;
    total.media_efetividade *= 100;
    
    // console.log(total.inicio_atividade.replaceAll("-","/"));
    // console.log(total.fim_atividade.replaceAll("-","/"));
  
    setTotalData(total);
    if (!selectedServer) setServers(serversFormatados);
    setDaySum(somaDias);

  }

  useEffect(() => {
    fetchTotalGraphics({
      startDate: format(startDate, 'dd-MM-yyyy'),
      endDate: format(endDate, 'dd-MM-yyyy'),
      selectedServer: selectedServer,
    })

  }, [startDate, endDate, selectedClients, selectedServer]);

  useEffect(() => {
    async function fetchClient() {
      type apiData = { data: IClient[] };
      const response = await api_url.get<apiData>("/users/me/clients");
      setClientes(response.data.data);
    }

    fetchClient();
  }, []);




  return (
    // fundo de tudo
    <Flex justifyContent="center" flexDir="column" w="100%" zIndex={5}>
      {calendarInput && (
        <Flex
          id="fora"
          flexDirection="column"
          onClick={(e) => {
            if ((e.target as Element).id === "fora") {
              setCalendarInput(false);
            }
          }}
          height="100vh"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          position="absolute"
          zIndex="5"
          background="rgba(40, 44, 52, 0.50)"
        >
          <Calendar
            className="calendar-filter"
            selectRange={true}
            defaultValue={[dataStartCalendar, dataEndCalendar]}
            onChange={(value: any, e) => {
              e.stopPropagation();
              setValue(value);
              setStartDate(value[0]);
              setEndDate(value[1]);
              setDataStartCalendar(value[0]);
              setDataEndCalendar(value[1]);
              setDataDifferenceCalendar(differenceInMonths(value[0], value[1]));
            }}
            value={value}
          />
          <Flex
            width="90%"
            background="#FFFFFF"
            padding="0.5rem 0.2rem 0.5rem"
            fontWeight="bold"
            flexDirection="column"
          >
            <Flex padding="0.3rem 1.5rem" minHeight="1.5rem" maxHeight="1.5rem">
              {dataDifferenceCalendar < -29 ? (
                <Flex padding="0.2rem 0.7rem" borderRadius="0.2rem">
                  <Text
                    width="100%"
                    color="#e42a2a"
                    fontSize="0.9rem"
                    textAlign="center"
                  >
                    Selecione um periodo de até um mês.
                  </Text>
                </Flex>
              ) : (
                ""
              )}
            </Flex>
          </Flex>
          <Flex
            width="90%"
            justifyContent="center"
            background="#FFFFFF"
            borderRadius="0 0 0.4rem 0.4rem"
            padding="0.6rem 0.4rem 0.3rem"
          >
            {dataDifferenceCalendar < -29 ? (
              <Button
                background="#665AE7"
                disabled
                color="#FFFFFF"
                onClick={() => {
                  setCalendarInput(false);
                }}
              >
                confirmar
              </Button>
            ) : (
              <Button
                background="#665AE7"
                color="#FFFFFF"
                onClick={() => {
                  setCalendarInput(false);
                }}
              >
                confirmar
              </Button>
            )}
          </Flex>
        </Flex>
      )}
      {/*  button back*/}
      <Link to="/Main">
        <ButtonBackPage>
          <BsArrowReturnLeft size={30} />
        </ButtonBackPage>
      </Link>

      {/* Topo da página */}
      <Flex justifyContent="space-between" p={4}>
        <Text fontWeight="bold" fontSize="2xl" textColor="#2654C5">
          Estatística
        </Text>

        <ButtonTopPage
          onClick={() => {
            setExpandFilter(!expandFilter);
          }}
        >
          <FiFilter color="white" />
        </ButtonTopPage>
      </Flex>
      {/* Botão do filtro */}

      <Flex justifyContent="center" p={1}>
        {expandFilter && (
          <Flex position="absolute" zIndex="4" w="90%" background="white">
            <FlexFilter className="shadow-equipament">
              <Text textColor="black" fontSize="lg">
                Clientes:
              </Text>

              <Flex>
                {clientes.map((cliente) => {
                  return (
                    <Flex key={cliente.id} flexDir="row" p={2}>
                      <Button
                        bg={selectedClients.includes(cliente.id) ? "#665AE7 !important" : "#F0EFEF !important"}
                        onClick={() => {
                          if (selectedClients.includes(cliente.id))
                            setSelectedClients(selectedClients.filter(id => id !== cliente.id));
                          else
                            setSelectedClients([...selectedClients, cliente.id]);
                        }
                        }
                      >
                        <img
                          src={`https://001.${cliente.baseDomain}/api/media/favicon/`}
                          alt={cliente.clientName}
                          width="30rem"
                        />
                      </Button>
                    </Flex>
                  );
                })}
              </Flex>
            </FlexFilter>
          </Flex>
        )}
      </Flex>

      {/* Gráfico */}
      <Flex zIndex={2} alignSelf="center" width={320} height={200}>
        {mode !== 'D' && <LineChart
          width={320} height={200}
          data={daySum}

          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >

          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="data" />
          <YAxis />
          <Tooltip />
          <Legend iconType="plainline" layout="horizontal" iconSize={0} />
          {Object.keys(graphFilter).map((key, i) =>
            graphFilter[key as keyof typeof graphFilter] &&
            <Line key={key + "gr"} name={key.split('_')[1]} type="natural" dataKey={key} stroke={colors[i]} />
          )
          }
        </LineChart>}
      </Flex>

      {/* botões de filtro */}
      <Flex flexDir="row" justifyContent="space-between" p={4}>
        <MenuButtonText
          onClick={() => {
            setStartDate(new Date());
            setEndDate(new Date());
            setMode('D');
          }}
          background={
            mode === 'D' ? "#665AE7 !important" : "transparent !important"
          }
          color={mode === 'D' ? "#FFFFFF" : "#665AE7"}
          transform={mode === 'D' ? "scale(1.05)" : "none"}
          to="/Statistic"
          icon={<CalendarIcon />}
        >
          D
        </MenuButtonText>
        <MenuButtonText
          onClick={() => {
            setStartDate(ultimoDomingo);
            setEndDate(hoje);
            setMode('S');
          }}
          background={
            mode === 'S' ? "#665AE7 !important" : "transparent !important"
          }
          color={mode === 'S' ? "#FFFFFF" : "#665AE7"}
          transform={mode === 'S' ? "scale(1.05)" : "none"}
          to="/Statistic"
          icon={<CalendarIcon />}
        >
          S
        </MenuButtonText>
        <MenuButtonText
          onClick={() => {
            setStartDate(ultimoMes);
            setEndDate(hoje);
            setMode('M');
          }}
          background={
            mode === 'M' ? "#665AE7 !important" : "transparent !important"
          }
          color={mode === 'M' ? "#FFFFFF" : "#665AE7"}
          transform={mode === 'M' ? "scale(1.05)" : "none"}
          to="/Statistic"
          icon={<CalendarIcon />}
        >
          M
        </MenuButtonText>
        <MenuButton
          background={
            mode === 'C' ? "#665AE7 !important" : "transparent !important"
          }
          color={mode === 'C' ? "#FFFFFF !important" : "#665AE7 !important"}
          transform={mode === 'C' ? "scale(1.05)" : "none"}
          onClick={() => {
            setCalendarInput(true);
            setMode('C');

          }}
          to="/Statistic"
          icon={
            <CalendarIcon
              color={
                mode === 'C' ? "#FFFFFF !important" : "#665AE7 !important"
              }
            />
          }
        ></MenuButton>
        { }
      </Flex>

      {/* Buttons resume and servers */}
      <Flex p={4} justifyContent="center">
        <FlexResumeAndServer>
          <Button
            fontSize="md"
            background="#"
            size="100"
            h="1.8rem "
            transition="0.8s"
            w={selectedServidores ? "55%" : selectedResumo ? "45%" : "50%"}
            borderRadius={selectedServidores ? "full" : "full full 0 0"}
            color={selectedServidores ? "#FFFFFF" : "#0c0c0c"}
            bgColor={
              selectedServidores ? "#665AE7 !important" : "transparent !important"
            }
            onClick={() => {
              setExpandResume(false);
              setExpandServer(true);
              setSelectedResumo(false);
              setSelectedServidores(true);
            }}
          >
            <Text> SERVIDORES</Text>
          </Button>
          <Button
            fontSize="md"
            size="100"
            h="1.8rem"
            transition="0.8s"
            w={selectedResumo ? "55%" : selectedServidores ? "45%" : "50%"}
            borderRadius={selectedResumo ? "full" : "full full 0 0"}
            color={selectedResumo ? "#FFFFFF" : "#0c0c0c"}
            bgColor={
              selectedResumo
                ? "#665AE7 !important"
                : "transparent !important"
            }
            onClick={() => {
              setExpandServer(false);
              setExpandResume(true);
              setSelectedResumo(true);
              setSelectedServidores(false);
            }}
          >
            <Text>PERFOMANCE</Text>
          </Button>
        </FlexResumeAndServer>
      </Flex>
      {/* lists perfomance*/}
      {expandResume && (
        <Flex className="ExpandResume" flexDir="column" maxHeight="50vh" p={2}>
          <Flex flexDir="column" overflowY="scroll" maxHeight="20rem" >

            <Flex justifyContent='space-between' p={4} alignItems='center' flexDirection="row" >

              <ButtonPerfomance
                onClick={() => setGraphFilter({ ...graphFilter, media_efetividade: !graphFilter.media_efetividade })}
                bg={graphFilter.media_efetividade ? "#665AE7 !important" : "#F0EFEF !important"} >

                <BoxPerfomanceText>
                  <Text fontWeight="bold" fontSize="4xl" textColor={!graphFilter.media_efetividade ? "black !important" : "#F0EFEF !important"}>
                    {totalData.media_efetividade?.toFixed(1)}
                  </Text>
                  <Text fontWeight="bold" fontSize="sm" textColor={!graphFilter.media_efetividade ? "#BDB7F1" : "#F0EFEF !important"}>
                    %
                  </Text>
                </BoxPerfomanceText>
                <Text fontWeight="bold" fontSize="sm" textColor={!graphFilter.media_efetividade ? "black !important" : "#F0EFEF !important"} >
                  Efetividade
                </Text>
              </ButtonPerfomance>


              <ButtonPerfomance onClick={() => setGraphFilter({ ...graphFilter, media_aderencia: !graphFilter.media_aderencia })} bg={graphFilter.media_aderencia ? "#665AE7 !important" : "#F0EFEF !important"} >
                <Flex alignItems='baseline' >
                  <Text fontWeight="bold" fontSize="4xl" textColor={!graphFilter.media_aderencia ? "black !important" : "#F0EFEF !important"} >
                    {totalData.media_aderencia?.toFixed(1)}
                  </Text>
                  <Text fontWeight="bold" fontSize="sm" textColor={!graphFilter.media_aderencia ? "#BDB7F1 !important" : "#F0EFEF !important"}>
                    %
                  </Text>
                </Flex>
                <Text fontWeight="bold" fontSize="sm" textColor={!graphFilter.media_aderencia ? "black !important" : "#F0EFEF !important"} >
                  Aderência
                </Text>
              </ButtonPerfomance>
            </Flex>

            <Flex justifyContent='space-between' p={4} alignItems='center'>
              <ButtonPerfomance bg={"#F0EFEF !important"}>
                <Flex alignItems='baseline' >
                  <Text fontWeight="bold" fontSize="4xl" textColor="black" >

                    {totalData.fim_atividade != null || totalData.inicio_atividade !=null ? differenceInDays(convertDate(totalData.fim_atividade), convertDate(totalData.inicio_atividade))+1 : "--"}
                    {/* {(totalData.media_efetividade - 100).toFixed(1)} */}
                  </Text>
                  <Text fontWeight="bold" fontSize="sm" textColor="#BDB7F1">
                  {Number(totalData ? differenceInDays(convertDate(totalData.fim_atividade), convertDate(totalData.inicio_atividade)) : 0)>1? "dias": "dia"}
                  </Text>
                </Flex>
                <Text fontWeight="bold" fontSize="sm" textColor="black">
                  Tempo de atividade
                </Text>
              </ButtonPerfomance>

              <ButtonPerfomance bg={"#F0EFEF !important"} >
                <Flex alignItems='baseline' >
                  <Text fontWeight="bold" fontSize="4xl" textColor="black" >
                  {((totalData.media_efetividade / (totalData.media_aderencia == 0? 1 : totalData.media_aderencia) )*100 ).toFixed(1)}
                  </Text>
                  <Text fontWeight="bold" fontSize="sm" textColor="#BDB7F1">
                    %
                  </Text>
                </Flex>
                <Text fontWeight="bold" fontSize="sm" textColor="black">
                  Produtividade
                </Text>
              </ButtonPerfomance>
            </Flex>
          </Flex>

        </Flex>
      )
      }
      {/* servidores */}
      <Flex flexDir="column">
        {expandServer && (
          <GenericValueList>

            {servers.map(({ server, media_aderencia, media_efetividade }) => {
              return (
                <ServerListBox key={"slist" + server.id}>
                  <ButtonServer
                    onClick={() => {
                      setSelectedServer(server.id);
                    }}
                    bg={
                      selectedServer === server.id
                        ? "#665AE7 !important"
                        : "white !important"
                    }
                  >
                    <Flex flexDir="row" w="100%">
                      <Flex>
                        <img
                          alt={server.serverName}
                          className="photoLittle"
                          src={server.url + "/api/media/favicon/"}
                        />
                      </Flex>
                      <Flex flexDir="column" w="100%" marginLeft="1rem">
                        <TextResumeTitle color={selectedServer === server?.id ? "white !important" : "#665AE7 !important"}
                        >
                          {server.serverName}
                        </TextResumeTitle>

                        <Flex
                          w="100%"
                          justifyContent="space-evenly"
                          transition="0.2s"
                          textColor={
                            selectedServer === server?.id
                              ? "white !important"
                              : "black !important"
                          }
                        >
                          <Flex>
                            <Flex
                              textColor={
                                selectedServer === server?.id
                                  ? "white !important"
                                  : "#665AE7 !important"
                              }
                            >
                              <GiSwapBag />
                            </Flex>

                            <TextValueServerFinancial>
                              {(media_aderencia * 100).toFixed(2)}%

                            </TextValueServerFinancial>
                          </Flex>
                          <Flex>
                            <Flex
                              textColor={
                                selectedServer === server?.id
                                  ? "white !important"
                                  : "#665AE7 !important"
                              }
                            >
                              <BiTrophy />
                            </Flex>
                            <TextValueServerFinancial>
                              {(media_efetividade * 100).toFixed(2)}%
                            </TextValueServerFinancial>
                          </Flex>

                        </Flex>
                      </Flex>
                    </Flex>
                  </ButtonServer>
                </ServerListBox>
              );
            })}

          </GenericValueList>

        )}
      </Flex>
      {expandServer &&(
      <ServerListBoxAll>
        <ButtonServerAll
          onClick={() => {
            setSelectedServer(undefined);
          }}
          bg={selectedServer === undefined ? "#665AE7 !important" : "white !important"}
        >
          <Flex flexDir="row" w="100%">
            <Flex flexDir="column" w="100%">
              <TextTodos textColor={selectedServer === undefined ? "white !important" : "#665AE7 !important"}>
                Todos
              </TextTodos>
            </Flex>
          </Flex>
        </ButtonServerAll>
      </ServerListBoxAll>)};
    </Flex >
  );
}
export default App;
