import React from 'react'
import { Button, Flex, Text } from '@chakra-ui/react';
import { HiStatusOnline, HiStatusOffline } from 'react-icons/hi';
import { IoMdPhonePortrait } from 'react-icons/io';
import { IoTvOutline } from 'react-icons/io5';

type props = {
  show: boolean;
  onSetFilter: (filter: any) => void;
  filter: any;
  clients: IClient[];
  selectedClients: string[];
  refresh: () => void;
  onSelectClient: (clients: string[]) => void;
}


export const FilterOverlay: React.FC<props> = ({ show, onSetFilter, filter, refresh, clients, selectedClients, onSelectClient }) => {

  if (!show) return (
    <>
    </>
  )


  return (
    <Flex w="100%" justifyContent="center" position="absolute" zIndex="2" marginTop="10rem">
      <Flex w="90%" background="transparent">
        <Flex
          className="shadow-filter"
          flexDir="column"
          zIndex={1}
          justifyContent="space-between"
          bg="white"
          borderRadius="2xl"
          p={3}
          textColor="#2654C5"
          w="100%"
          h="100%"
        >
          <Text textColor="black" fontSize="1rem" fontWeight="bold" p="0 0.5rem">
            Tipo de equipamento{" "}
          </Text>


          <Flex className="equipamentos" flexDir="row" p="0.2rem 0.6rem">
            <Button
              textColor="black"
              size="100"
              fontSize="2rem"
              h="3rem"
              w="3rem"
              marginRight="1rem"
              background={filter.pos ? "#665AE7 !important" : "#F0EFEF !important"}
              color={filter.pos ? "#FFFFFF !important" : "#0C0C0C !important"}
              onClick={() => { onSetFilter({ ...filter, pos: !filter.pos }) }}
            >
              <IoMdPhonePortrait />
            </Button>
            <Button
              textColor="black"
              size="100"
              fontSize="2rem"
              h="3rem "
              w="3rem"
              marginRight="1rem"
              background={filter.tv ? "#665AE7 !important" : "#F0EFEF !important"}
              color={filter.tv ? "#FFFFFF !important" : "#0C0C0C !important"}
              onClick={() => {
                onSetFilter({ ...filter, tv: !filter.tv })
              }}
            >
              <IoTvOutline />
            </Button>


          </Flex>

          <Text textColor="black" fontSize="1rem" fontWeight="bold" p="0 0.5rem">
            Status
          </Text>
          <Flex className="equipamentos" flexDir="row" p="0.2rem 0.6rem">
            <Button
              textColor="black"
              size="100"
              fontSize="2rem"
              h="3rem"
              w="3rem"
              marginRight="1rem"
              background={filter.status === true ? "#665AE7 !important" : "#F0EFEF !important"}
              color={filter.status === true ? "#FFFFFF !important" : "#0C0C0C !important"}
              onClick={() => {
                if (filter.status === true)
                  onSetFilter({ ...filter, status: undefined });
                else
                  onSetFilter({ ...filter, status: true });

                refresh();
              }}
            >
              <HiStatusOnline />
            </Button>
            <Button
              textColor="black"
              size="100"
              fontSize="2rem"
              h="3rem"
              w="3rem"
              marginRight="1rem"
              background={filter.status === false ? "#665AE7 !important" : "#F0EFEF !important"}
              color={filter.status === false ? "#FFFFFF !important" : "#0C0C0C !important"}
              onClick={() => {
                if (filter.status === false)
                  onSetFilter({ ...filter, status: undefined });
                else
                  onSetFilter({ ...filter, status: false });

                refresh();
              }}
            >
              <HiStatusOffline />
            </Button>

          </Flex>
          <Text textColor="black" fontSize="1rem" fontWeight="bold" p="0 0.5rem">
            Cliente
          </Text>
          <Flex>
            {clients.map((client) => (
              <Flex key={client.id} flexDir="row" p="0.2rem 0.6rem">
                <Button
                  // aqui
                  padding="1rem"
                  height="4rem"
                  bg={selectedClients.includes(client.id) ? "#665AE7 !important" : "#F0EFEF !important"}
                  color={selectedClients.includes(client.id) ? "#FFFFFF !important" : "#0C0C0C !important"}
                  onClick={() => {
                    if (selectedClients.includes(client.id))
                      onSelectClient(selectedClients.filter(id => id !== client.id));
                    else
                      onSelectClient([...selectedClients, client.id]);
                  }}>
                  <img className="photoLittle" src={`https://001.${client.baseDomain}/api/media/favicon/`} />
                </Button>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>

    </Flex>
  )
}

export default FilterOverlay;