import { Button, Flex, Text, Image, Box } from "@chakra-ui/react";
import Background from "../../SVG/BackGroundAzul.svg";
// import {GoogleApiWrapper} from 'google-maps-react';

import React, { useState } from "react";
import { BsArrowReturnLeft } from "react-icons/bs";
import { Link, RouteComponentProps } from "react-router-dom";
import { useEffect } from "react";
import api_url from "../../Services/Api";


//today's date
// const today = format(new Date(), "dd/MM/yy");

const EquipamentButton: React.FC<RouteComponentProps<{ macAddress: string }>> = ({ match }) => {
  const [tv, setTv] = useState<ITvs>();

  useEffect(() => {
    async function fetchTv(mac: string) {
      const tv = await api_url.get<ITvs>(`/telas/${mac}`).then(res => res.data);
      console.log(tv);
      setTv(tv);
    }

    fetchTv(match.params.macAddress);
  }, [match.params.macAddress]);

  return (
    // fundo de tudo
    <Flex
      justifyContent="center"
      flexDir="column"
      alignItems="center"
      width="100%"
    >
      <Flex zIndex={-1} position="absolute" w="100%" h="100%">
        <img
          src={Background}
          alt="background"
          style={{ width: "100vw", position: "fixed", zIndex: -1 }}
        />
      </Flex>

      <Flex alignSelf="self-end" width="100%">
        <Link to='/Equipament' replace>
          <Button
            className="Button back"
            // alignSelf="left"
            w="4rem"
            colorScheme="tranparent"
            textColor="white"
            fontWeight="bold"
          // onClick={() => history.goBack()}
          >
            <BsArrowReturnLeft size={30} />
          </Button>
        </Link>
      </Flex>

      <Text textColor="white" fontSize="3xl" p={2}>
        Equipamento
      </Text>

      <Flex w="90%" background="white" borderRadius="full">
        <Flex
          flexDir="column"
          zIndex={1}
          justifyContent="space-between"
          bg="white"
          borderRadius="3xl"
          p={3}
          textColor="#2654C5"
          boxShadow="2xl"
          w="100%"
          h="100%"
        >
          <Text textColor="#44496F" fontSize="xl" p={1} fontWeight='600'>
            {/* {pos?.tipo} */}
            {tv?.tipo}
          </Text>
          <Flex flexDir='column' marginTop='1rem'>
            <Text textColor="#44496F" fontSize="xl" fontWeight='600'>
              {/* {pos?.estabelecimento_nome} */}
              {tv?.estabelecimento_nome}
            </Text>
            <Text textColor="#ACA5EF" fontSize="xl" fontWeight='600'>
              {/* {(pos?.server as any)?.url} */}
              {(tv?.server as any)?.url.replace("https://", "").replace(".", " - ").replace(".app", "")}

            </Text>
            <Flex justifyContent='space-between'>
              <Text textColor="#44496F" fontSize="md" >

                {/* {pos?.mac_address}                */}
                {tv?.mac_address}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        bg="gray"
        marginTop="-2rem"
        zIndex={0}
        boxShadow="2xl"
        h="100%"
        w="80%"
        borderRadius="3xl"
      >
        <Flex h="30rem" borderRadius="full">
          <Box borderRadius="full" h="30rem" >
            <Image borderRadius="3xl" src="https://i.ibb.co/D5Wr35C/mapa45.png" />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
export default EquipamentButton;
